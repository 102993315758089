import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf, ViewChild
} from '@angular/core';
import {ControlContainer, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {ConditionBuilderOptions, CriteriaOperator, Entity, Field, Utility} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {ModelService} from '../../../../../../api';

import {FieldConverterService} from '../../../converter/field-converter.service';
import {CustomInput} from '../../custom-input';

@Component({
  selector: 'ui-field-autocomplete',
  templateUrl: './field-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './field-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FieldAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FieldAutocompleteInputComponent),
    multi: true,
  }, FieldConverterService],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldAutocompleteInputComponent extends CustomInput<Field | Field[]> implements OnInit, Validator, OnChanges {
  @Input()
  public entityName: string;
  @Input()
  public localized: boolean = true;
  @Input()
  public allowDotWalk: boolean = true;
  @Input()
  public additionalFields: Field[] = [];
  @Input()
  public search: (result: Field[], queryString?: string) => MaybePromise<Field[]>;
  @Input()
  public conditionBuilderOptions: ConditionBuilderOptions[];
  @Input()
  public searchOperator: CriteriaOperator;
  @Output()
  public onSelect: EventEmitter<Field> = new EventEmitter();
  @ViewChild('focusInput')
  public focusInput: ElementRef;
  public overlayVisible: boolean;
  public entityMeta: Entity;
  public preventFocus: boolean = false;

  public constructor(private modelService: ModelService, cdr: ChangeDetectorRef, private fieldConverter: FieldConverterService) {
    super(cdr);
    this.fieldConverter.entityMetaLoader = () => this.loadMetaData();
    this.converter = this.fieldConverter;
  }

  public get filled(): boolean {
    return this.multiple ? !!(this.value as Field[])?.length : !!this.value;
  }

  /**
   * split value label into two pieces
   *
   * the first part is allowed to be shortened (...)
   * the second part is always visible
   */
  public getValueLabel(field: Field): string[] {
    if (!field) {
      return [];
    }

    const label = field.label;
    const labels = label.split('.');
    const singleField = labels.length === 1;
    const finalLabels = [labels.slice(0, labels.length - 1).join('.'), labels[labels.length - 1]].filter(x => !!x);

    // if the single field has an icon add it as i tag
    if (singleField && field.icon) {
      // add to first label
      finalLabels[0] = `<i class="${field.icon}"></i> ${finalLabels[0]}`;
    }

    return finalLabels;
  }

  public ngOnInit(): void {
    this.fieldConverter.additionalFields = this.additionalFields;
    this.fieldConverter.localized = this.localized;
    this.entityMeta = this.loadMetaData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityName) {
      this.entityMeta = this.loadMetaData();
    }
  }

  public toggleOverlay(): void {
    if (this.disable) {
      return;
    }
    if (this.preventFocus) {
      this.preventFocus = false;
      return;
    }
    this.overlayVisible = !this.overlayVisible;
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 100);
  }

  public focusInputTrap(preventFocus: boolean = true): void {
    setTimeout(() => {
      this.preventFocus = preventFocus;
      this.focusInput.nativeElement.focus();
    }, 100);
  }

  public validate(control?: UntypedFormControl): ValidationErrors {
    if (!this.value && this.require && !this.disable) {
      return {required: true};
    }
    return null;
  }

  public clear(): void {
    this.value = null;
    this.onSelect.emit();
  }

  public addValue($event: string): void {
    const field = this.converter.getAsObject($event);
    const additionalField = _.find(this.additionalFields, {name: $event});
    if (!additionalField) {
      field.path = Utility.getDotWalkPath($event).join('.');
    }
    if (this.multiple) {
      this.value ??= [];
      this.value = [...this.value as Field[], field];
    } else {
      this.value = field;
    }
  }

  public getPreSelectedValue(): string | string [] {
    return this.rawValue as string | string [];
  }

  public removeValue(field: Field): void {
    _.remove(this.value as Field[], {path: field.path, name: field.name});
    this.value = [...this.value as Field[]];
  }

  private loadMetaData(): Entity {
    // convert name in case it was provided as my type
    return this.modelService.getEntity(this.entityName);
  }
}
