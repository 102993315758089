<div class="one-input-textarea">
  <ng-container [ngSwitch]="editorMode">
    <ng-container *ngSwitchCase="LargeTextEditorMode.HTML">
      <ndc-dynamic (ndcDynamicCreated)="componentCreated($event)" [ndcDynamicComponent]="wysiwygEditorComponent" [ndcDynamicInputs]="{
                  value,
                  label,
                  disable,
                  compact,
                  require,
                  updateWhileTyping,
                  container
                 }" [ndcDynamicOutputs]="ngModelOutputs"></ndc-dynamic>
    </ng-container>
    <ng-container *ngSwitchCase="LargeTextEditorMode.CODE_EDITOR">
      <ndc-dynamic (ndcDynamicCreated)="componentCreated($event)" [ndcDynamicComponent]="codeEditorComponent" [ndcDynamicInputs]="{
                  value,
                  label,
                  disable,
                  language: language ?? 'javascript',
                  minHeight: rows * 40,
                  recordClass,
                  queryClass,
                  enableMinimap,
                  require,
                  codeEditorOptions,
                  codeContext,
                  indentOnInit,
                  container
                 }" [ndcDynamicOutputs]="ngModelOutputs"></ndc-dynamic>
    </ng-container>
    <ng-container *ngSwitchCase="LargeTextEditorMode.MARKDOWN">
      <ndc-dynamic (ndcDynamicCreated)="componentCreated($event)" [ndcDynamicComponent]="markdownEditorComponent" [ndcDynamicInputs]="{
                  value: value || {html: '', markdown: ''},
                  recordClass: recordClass,
                  inline: true,
                  disable: disable,
                  label: label,
                  require: require,
                  recordId,
                  container
                 }" [ndcDynamicOutputs]="ngModelOutputs"></ndc-dynamic>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="filled" [label]="label" [linkifyRows]="rows" [linkify]="linkify"
                        [require]="require" [value]="value" [container]="container">
      <textarea [(ngModel)]="value" [autoResize]="false" [cols]="60" [disabled]="disable" [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData"
                [required]="require" [rows]="rows" class="one-input one-input--wrap {{disable ? 'one-input--readonly' : ''}}" name="native"
                pInputTextarea></textarea>
      </ui-input-wrapper>
    </ng-container>
    <ng-container *ngIf="isI18n && !disable" [ngTemplateOutlet]="chooseLanguageButtons"></ng-container>
  </ng-container>
</div>

<ng-template #chooseLanguageButtons>
  <div class="one-input__i18n">
    <p-menu #languageOptions [model]="languageOptionButtons" [popup]="true" appendTo="body"></p-menu>
    <ui-button (clicked)="languageOptions.toggle($event.originalEvent)" [spinner]="false" [type]="['icon-only']" icon="fas fa-fw fa-ellipsis-v"
               label="{{'Input.TranslateInput' | translate}}"></ui-button>
  </div>
</ng-template>
