<ng-container *ngIf="!grouping">
  <ui-simple-table #simpleTable (onAfterLoad)="onAfterLoad.emit($event)" (onColumnChange)="onColumnChange.emit($event)"
                   (onContextFieldSelection)="doContextFieldSelection($event)" (onEditCancel)="onEditCancel.emit($event)" (onEditSave)="onEditSave.emit($event)"
                   (onFilter)="onFilter.emit($event)" (onRefresh)="onRefresh.emit($event)" (onReset)="onReset.emit()" (onStateSave)="doStateSave($event)"
                   [(selection)]="selection" [allowCsvExport]="allowCsvExport" [allowJsonExport]="allowJsonExport" [allowPdfExport]="allowPdfExport"
                   [allowXlsExport]="allowXlsExport" [availableColumns]="availableColumns" [canUseQueryBuilder]="canUseQueryBuilder"
                   [columnToggler]="columnToggler" [columns]="columns" [compact]="compact" [customActions]="customActions" [dataKey]="dataKey"
                   [defaultActions]="defaultActions" [enableGrouping]="enableGrouping" [entityMeta]="entityMeta" [exactTotalRecords]="exactTotalRecords"
                   [globalSearch]="globalSearch" [lazy]="lazy" [list]="list" [loading]="loading" [multiSortMeta]="multiSortMeta" [name]="name"
                   [resizeable]="resizeable" [rowTrackBy]="rowTrackBy" [rows]="rows" [selectionMode]="selectionMode" [styleClass]="styleClass" [title]="title"
                   [totalRecords]="totalRecords" [values]="values">

    <ng-template #tools>
      <ng-container [ngTemplateOutlet]="tTools"></ng-container>
    </ng-template>
    <ng-template #header>
      <ng-container [ngTemplateOutlet]="tHeader"></ng-container>
    </ng-template>
    <ng-template #subHeader>
      <ng-container [ngTemplateOutlet]="tSubHeader"></ng-container>
    </ng-template>
    <ng-template #rowActions let-data="data" let-index="index">
      <ng-container *ngTemplateOutlet="tRowActions; context: {data, index}"></ng-container>
    </ng-template>
    <ng-template #toolbarButton let-table="table">
      <ng-container [ngTemplateOutletContext]="{table}" [ngTemplateOutlet]="tToolbarButton"></ng-container>
    </ng-template>

  </ui-simple-table>
</ng-container>

<ng-container *ngIf="grouping">
  <ui-grouping-table #groupingTable (onAfterLoad)="onAfterLoad.emit($event)" (onColumnChange)="onColumnChange.emit($event)"
                     (onContextFieldSelection)="doContextFieldSelection($event)" (onEditCancel)="onEditCancel.emit($event)"
                     (onEditSave)="onEditSave.emit($event)" (onFilter)="onFilter.emit($event)" (onRefresh)="onRefresh.emit($event)" (onReset)="onReset.emit()"
                     (onStateSave)="doStateSave($event)" [(selection)]="selection" [allowCsvExport]="allowCsvExport" [allowJsonExport]="allowJsonExport"
                     [allowPdfExport]="allowPdfExport" [allowXlsExport]="allowXlsExport" [availableColumns]="availableColumns"
                     [canUseQueryBuilder]="canUseQueryBuilder" [columnToggler]="columnToggler" [columns]="columns" [compact]="compact"
                     [customActions]="customActions" [dataKey]="dataKey" [defaultActions]="defaultActions" [enableGrouping]="enableGrouping"
                     [entityMeta]="entityMeta" [fixedGrouping]="fixedGrouping" [globalSearch]="globalSearch" [groupByField]="groupByField" [lazy]="lazy"
                     [list]="list" [loading]="loading" [multiSortMeta]="multiSortMeta" [name]="name" [resizeable]="resizeable" [rowTrackBy]="rowTrackBy"
                     [rows]="rows" [selectionMode]="selectionMode" [styleClass]="styleClass" [totalRecords]="totalRecords">

    <ng-template #tools>
      <ng-container [ngTemplateOutlet]="tTools"></ng-container>
    </ng-template>
    <ng-template #header>
      <ng-container [ngTemplateOutlet]="tHeader"></ng-container>
    </ng-template>
    <ng-template #subHeader>
      <ng-container [ngTemplateOutlet]="tSubHeader"></ng-container>
    </ng-template>
    <ng-template #rowActions let-data="data" let-index="index">
      <ng-container *ngTemplateOutlet="tRowActions; context: {data, index}"></ng-container>
    </ng-template>
    <ng-template #toolbarButton let-table="table">
      <ng-container [ngTemplateOutletContext]="{table}" [ngTemplateOutlet]="tToolbarButton"></ng-container>
    </ng-template>

  </ui-grouping-table>
</ng-container>