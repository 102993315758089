import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Host, Input, Optional, SkipSelf, ViewChild} from '@angular/core';
import {ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {I18n, ValidationLevel} from '@wspsoft/frontend-backend-common';
import {Password} from 'primeng/password';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true,
  },
    {
      provide: CustomInput,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true
    }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordInputComponent extends CustomInput<string> implements Validator {
  @Input()
  public minlength: number;
  @Input()
  public strongRegex: string;
  @Input()
  public showShowPassword: boolean = true;
  @Input()
  public disableAutofill: boolean = true;
  @ViewChild('nativeInput', {static: false})
  protected nativeInput: Password;
  private pRequirementsText: string | I18n;

  public constructor(private translate: TranslateService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get feedback(): boolean {
    return !!this.strongRegex?.length;
  }

  @Input()
  public get requirementsText(): string {
    if (typeof this.pRequirementsText === 'string') {
      return this.pRequirementsText;
    }

    return this.pRequirementsText?.[this.translate.currentLang];
  };

  public set requirementsText(value: string | I18n) {
    this.pRequirementsText = value;
  }


  public validate(): ValidationErrors {
    if (!this.disable) {
      const testStrength = this.nativeInput?.testStrength(this.value || '');
      if (this.feedback && testStrength && testStrength !== 3) {
        return {
          custom: {
            message: this.translate.instant('Password.Weak'),
            valid: false,
            severity: ValidationLevel.ERROR
          }
        };
      }
    }
  }
}
