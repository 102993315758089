import {DesignerEntity} from './designer.entity';
import {LayoutFormToLayoutSection} from './layout-form-to-layout-section';
import {LayoutList} from './layout-list';
import {LayoutSectionToSectionField} from './layout-section-to-section-field';
import {TabLayoutSectionToSectionTab} from './tab-layout-section-to-section.tab';

/**
 * configure section with all properties
 */
export abstract class LayoutSection extends DesignerEntity {
  public lazyLoaded?: boolean;
  public showHeader?: boolean;
  public readonly?: string;
  public rendered?: string;
  public attributeId?: string;
  public tourStepId?: string;
  public layoutSectionToSectionFields?: LayoutSectionToSectionField[];
  public layoutForms?: LayoutFormToLayoutSection[];

  // nested layout
  public initScript?: string;
  public filterScript?: string;
  public layoutId?: string;

  // related list
  public listDefinitionId?: string;
  public listDefinition?: LayoutList;
  public editFilterScript?: string;
  public afterSave?: string;
  public editButton?: string;
  public openAsFullList?: boolean;
  public relationId?: string;
  public showInListId?: string;
  public additionalAttributesDisplay?: string[];
  public additionalAttributesQuery?: string[];

  // variable stuff
  public targetEntityId?: string;
  public targetData?: any;
  public createNewRecord?: boolean;
  public submitCondition?: string;
  public entityId?: string;
  public loadVariables?: boolean;
  public liveUpdatable?: boolean;
  public variableField?: string;

  // attachment section
  public maxWidth?: number;
  public maxHeight?: number;
  public maxSize?: number;
  public fileType?: string[];

  // widget section
  public widgetId?: string;

  // tab section
  public defaultTabId?: string;
  public tabContents?: TabLayoutSectionToSectionTab[];
}
