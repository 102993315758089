<ui-dialog [(visible)]="showExportDialog" [dialogFooter]="true" header="{{'List.Export.Header' | translate}}" name="ExportDialog">
  <ng-template #content>
    <p>{{ 'List.Export.SelectMessage' | translate }}</p>
    <ui-select-button [(ngModel)]="selectedExportType" [options]="exportActions"></ui-select-button>
    <ui-input-text *ngIf="selectedExportType === 'csv'" [(ngModel)]="csvSeparator" label="{{'List.Export.CSVSeparator' | translate}}"
                   name="separator"></ui-input-text>
    <ui-inputswitch [(ngModel)]="translateValues" label="{{'List.Export.TranslateValues' | translate}}"></ui-inputswitch>
    <ui-inputswitch *ngIf="allowAllFields" [(ngModel)]="allAttributes" label="{{'List.Export.AllAttributes' | translate}}"></ui-inputswitch>
    <ui-inputswitch *ngIf="translateValues" [(ngModel)]="useDisplayTransformation"
                    label="{{'List.Export.UseDisplayTransformation' | translate}}"></ui-inputswitch>
  </ng-template>

  <ng-template #footer>
    <ui-button (clicked)="export(); $event.cb();" icon="fas fa-fw fa-download" label="{{'List.Export.Export' | translate}}" type="primary"></ui-button>
  </ng-template>
</ui-dialog>
