import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {EmailAccount} from './email-account';
import {KolibriEntity} from './kolibri-entity';
import {MessageTemplate} from './message-template';
import {Rule} from './rule';
import {UserGroup} from './user-group';

export enum ReportRuleAttachmentType {
  PDF = 'pdf',
  WIDGET = 'widget'
}

export abstract class ReportRuleToUserGroup extends KolibriEntity {
  public reportRule?: ReportRule;
  public reportRuleId: string;
  public userGroup?: UserGroup;
  public userGroupId: string;
}

export abstract class ReportRuleToUserGroupCC extends KolibriEntity {
  public ccReportRule?: ReportRule;
  public ccReportRuleId: string;
  public ccUserGroup?: UserGroup;
  public ccUserGroupId: string;
}

export abstract class ReportRule extends Rule {
  public recipients?: string[];
  public queryCondition?: CriteriaQueryJson | string;
  public emailTemplate?: MessageTemplate;
  public emailTemplateId?: string;
  public pdfTemplates?: string[];
  public widgets?: string[];
  public account?: EmailAccount;
  public accountId?: string;
  public sendIfRecordsEmpty?: boolean;
  public attachmentType?: ReportRuleAttachmentType[];
  public userGroups?: ReportRuleToUserGroup[];
  public ccRecipientFields?: string[];
  public ccRecipientsScript?: any;
  public ccRecipients?: string[];
  public ccUserGroups?: ReportRuleToUserGroupCC[];

  // cron & scheduler stuff
  public repeatable?: boolean;
  public reportCronDescription?: string;
  public sendAtDate?: string;
  public minute?: string;
  public second?: string;
  public dayOfWeek?: string;
  public hour?: string;
  public month?: string;
  public dayOfMonth?: string;
}
