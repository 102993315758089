import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CriteriaFunction, CriteriaOperator, CriteriaQuery, KolibriEntity, RenderType, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {FilterMetadata, LazyLoadEvent, SortMeta} from 'primeng/api';
import {IListComponent} from '../../../../util/list-component';
import {ListUtil} from '../../../../util/list-util';
import {DatatableColumn} from '../datatable/datatable.component';
import {SimpleTableComponent} from '../simple-table/simple-table.component';


type GroupingType = {
  values?: KolibriEntity[];
  totalRecords?: number;
  query?: CriteriaQuery<KolibriEntity>;
  loading?: boolean;
  ready?: boolean;
  selection?: any[];
  pSelection?: any[];
  exactTotalRecords?: number;
  filters?: { [key: string]: any };
  multiSortMeta?: SortMeta[];
  rows?: number;
  first?: number;
  aggregationValue?: { [key: string]: number };
  editableRows?: string[];
};

@Component({
  selector: 'ui-grouping-table',
  templateUrl: './grouping-table.component.html',
  styleUrls: ['./grouping-table.component.scss', '../datatable/datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupingTableComponent extends SimpleTableComponent implements OnInit {
  @Input()
  public groupByField: string;
  public query: CriteriaQuery<KolibriEntity>;
  @Input()
  public innerInitialRows: number = 10;
  public groupingValues: { [key: string]: GroupingType } = {};
  public groupingColumns: DatatableColumn[];
  public groupByColumn: DatatableColumn;
  public selectAllCheckboxValue: boolean = false;
  private first: number;

  public get columnCount(): number {
    let count = this.groupingColumns.length;
    if (this.hasSelectAction) {
      count++;
    }
    if (this.hasAnyColumnAction && !this.hasSelectAction) {
      count++;
    }
    return count;
  }

  public get showPageAggregationInGrouping(): boolean {
    return _.some(this.groupingColumns, col => this.showPageAggregationInGroupingForColumn(col));
  }

  public get showTotalAggregationInGrouping(): boolean {
    return _.some(this.groupingColumns, col => this.showTotalAggregationInGroupingForColumn(col));
  }

  public get isRelatedList(): boolean {
    return 'area' in this.list;
  }

  public get hasEditAllAction(): boolean {
    if (!this.hasEditAction) {
      return false;
    }
    // has some value in any open grouping values that has no row edit enabled
    return Object.values(this.groupingValues)
      .filter(v => v.ready && !!v.values)
      .flatMap(value => value.values)
      .some(v => !this.table.isRowEditing(v));
  }

  public get hasSaveCancelAllAction(): boolean {
    if (!this.hasEditAction) {
      return false;
    }
    // has some value in any open grouping values that has row edit enabled
    return Object.values(this.groupingValues)
      .filter(v => v.ready && !!v.values)
      .flatMap(value => value.values)
      .some(v => this.table.isRowEditing(v));
  }

  public showPageAggregationInGroupingForColumn(col: DatatableColumn): boolean {
    // preparation for aggregation in groupingHeaders #6510
    return false; // after #6510 replace "false" with "this.showAggregation(col) && col.perPageAggregation";
  }

  public showTotalAggregationInGroupingForColumn(col: DatatableColumn): boolean {
    return !!col.showAggregation && col.totalAggregation;
  }

  public async doLoad($event: LazyLoadEvent): Promise<any[]> {
    try {
      this.onBeforeLoad.emit($event);
      const groupFilter = $event.filters[this.groupByField];
      const groupFilterValue = groupFilter?.value;
      if (!_.isNullOrEmpty(groupFilterValue) || groupFilterValue instanceof Date) {
        if (this.onFilter.observed) {
          this.onFilter.emit({
            field: this.groupByField,
            value: this.convertFilterValueToCriteriaValue(groupFilterValue, this.groupByColumn),
            operator: this.convertDefaultFilterToCriteriaOperator(groupFilter)
          });
        } else {
          this.table.filter(groupFilterValue, this.contextFieldSelection, 'equals');
        }
        $event.filters[this.groupByField].value = null;
      }
      this.query = (await this.list.generateQuery($event ?? {}));
      if (this.query) {
        this.loading = true;
        this.groupingValues = {};
        this.values = [];
        this.cdr.detectChanges();
        const query = this.query.clone();
        query.offset($event.first).limit($event.rows);

        // maybe count was last
        query.criteriaFunction = CriteriaFunction.NOP;
        query.addGroupBy(this.groupByField);
        query.groupByFirstOnly(true);
        const groupedValues: KolibriEntity[] = await query.getResults();

        if (this.groupByColumn.meta && this.groupByColumn.meta.entityClass === 'Relation') {
          await this.list.doBulkLoadOfFields(groupedValues, [this.groupByField]);
        }

        this.totalRecords = await query.clone().offset(0).limit(undefined).getRowCount();
        this.exactTotalRecords = this.totalRecords;
        this.values = groupedValues;
        await _.parallelDo(groupedValues, async (val: KolibriEntity) => {
          const groupingReadyKey = this.getGroupingReadyKey(val, this.groupByField);
          const subQuery = (await this.getSubQuery(val, this.groupByField)).offset(0).limit(this.rows);
          this.groupingValues[groupingReadyKey] = this.createGroupingType(subQuery, [], undefined, this.groupingValues[groupingReadyKey]);
        });
        return this.values;
      }
    } finally {
      this.loading = false;
      this.onAfterLoad.emit($event);
    }
  }

  public ngOnInit(): void {
    this.loading = true;
    super.ngOnInit();
    if (!this.fixedGrouping) {
      // set the groupBy context menu visibility
      _.find(this.contextMenu, (m) => m.id === 'GroupBy').visible = false;
      _.find(this.contextMenu, (m) => m.id === 'NoGroupBy').visible = true;
    }
    this.contextMenu.push({
      label: this.translate.instant('List.CloseAll'),
      id: 'CloseAll',
      visible: true,
      icon: 'fas fa-fw fa-compress-alt',
      command: (): void => {
        Object.values(this.groupingValues).forEach(groupingValue => groupingValue.ready = false);
      },
    });
    if (this.list.generateQuery) {
      this.load = _.debounce(this.doLoad, 100).bind(this);
    }
    this.calculateColumns();
    this.loading = false;
  }

  /**
   * enabling edit mode for all rows on page.
   */
  public enableEditForAll(): void {
    // enabling edit for inner values
    for (const value of Object.values(this.groupingValues)) {
      if (value.ready) {
        value.values.forEach(v => {
          value.editableRows.push(v.id);
          this.table.initRowEdit(v);
        });
      }
    }
  }

  public isEditingValue(gType: GroupingType, value: KolibriEntity): boolean {
    return gType.editableRows?.includes(value.id);
  }

  public getGroupingValueObject(data: any, col: DatatableColumn): GroupingType {
    return this.groupingValues[this.getGroupingReadyKey(data, col.field)] ?? {};
  }

  /**
   * saves all rows with enabled row edit and disabling edit mode for all rows on page.
   * the dirty flag will be checked in onEditSave
   */
  public saveAllEdited(): void {
    const valuesToUpdate: any[] = [];
    // saving all edit for inner values
    for (const value of Object.values(this.groupingValues)) {
      if (value.ready && value.values) {
        value.values.forEach(v => {
          if (this.table.isRowEditing(v)) {
            this.table.saveRowEdit(v, this.table.el.nativeElement);
            valuesToUpdate.push({
              gType: value,
              data: v
            });
          }
        });
      } else if (!value.ready && value.values) {
        // do cancel for all not ready tables if necessary
        value.values.forEach(v => {
          if (this.table.isRowEditing(v)) {
            this.table.cancelRowEdit(v);
            this.cancelEdit(value, v);
          }
        });
      }
    }
    if (valuesToUpdate.length) {
      this.onEditSave.emit(valuesToUpdate.map(vtu => vtu.data));
      for (const valObj of valuesToUpdate) {
        _.remove(valObj.gType.editableRows, id => id === valObj.data.id);
      }
    }
  }

  /**
   * cancels all rows with enabled row edit and disabling edit mode for all rows on page.
   */
  public cancelAllEdited(): void {
    // cancelling all edit for inner values
    for (const value of Object.values(this.groupingValues)) {
      if (value.values) {
        value.values.forEach(v => {
          if (this.table.isRowEditing(v)) {
            this.table.cancelRowEdit(v);
            this.cancelEdit(value, v);
          }
        });
      }
    }
  }

  public colSortOrder(field: string): number {
    const t = _.find(this.multiSortMeta, {field});
    return t ? t.order : 0;
  }

  public filterState(filterName: string): FilterMetadata {
    if (this.tableState && this.tableState.filters && (filterName in this.tableState.filters)) {
      return (this.tableState.filters[filterName]) as FilterMetadata;
    }
    return {};
  }

  public refresh(clearSelection?: boolean, externalRefresh: boolean = true): void {
    if (clearSelection) {
      this.selection = [];
      this.table.selection = [];
    }
    if (externalRefresh) {
      this.table?._filter();
      this.calculateColumns();
      if (!this.groupByColumn) {
        ListUtil.throwNoColumnMessage(this.groupByField, this.entityMeta, this.modelService, this.translate, this.messageService);
        return;
      }
    }
  }

  public async refreshInner(groupingType: GroupingType, data: any, col: DatatableColumn): Promise<void> {
    groupingType.first = 0;
    await this.getGroupList(data, col).load(this.groupingTypeToLazyLoadEvent(groupingType));
    this.cdr.detectChanges();
  }

  public aggregate({field, aggregation}: DatatableColumn): number {
    const agg = Utility.aggregate(field, aggregation, this.values);
    return isNaN(agg) ? null : agg;
  }

  public getGroupList(data: any, col: DatatableColumn): Partial<IListComponent<any>> {
    const self = this;
    return {
      ...self.list,
      get query(): CriteriaQuery<KolibriEntity> {
        return self.query;
      },
      viewEntry($event: MouseEvent, selected: any): Promise<void> {
        return self.list.viewEntry($event, selected);
      },
      load: async ($event): Promise<any[]> => {
        const key = self.getGroupingReadyKey(data, col.field);
        if (!self.groupingValues[key]) {
          const subQuery = await self.getSubQuery(data, col.field);
          self.groupingValues[key] = self.createGroupingType(subQuery, []);
        }
        const groupingType = self.groupingValues[key];

        groupingType.loading = true;
        groupingType.filters = $event.filters;

        const query = groupingType.query.clone();
        ListUtil.applyColumnFilter($event, query, self.groupingColumns, self.typeUtility);
        ListUtil.applySortMeta($event, query, self.groupingColumns, self.typeUtility);

        const limit = $event.rows || groupingType.rows;
        void self.setInternalRowCount(groupingType, $event, limit);
        const vals: KolibriEntity[] = await query.limit(limit).offset($event.first).getResults();

        await self.list.doBulkLoadOfFields(vals);

        groupingType.values = vals;

        await _.parallelDo(self.groupingColumns, async (column: DatatableColumn): Promise<void> => {
          if (_.isNull(groupingType.aggregationValue)) {
            groupingType.aggregationValue = {};
          }
          if (column.aggregation) {
            groupingType.aggregationValue[column.field] = await ListUtil.calculateFullAggregation(column.aggregation, query, column.showAggregation);
          }
        });

        groupingType.loading = false;
        return vals;
      }
    };
  }

  public getSubQuery(data: any, fieldName: string): Promise<CriteriaQuery<KolibriEntity>> {
    return new Promise(resolve => {
      Utility.doDotWalk(data, fieldName, x => {
        const query = this.query.clone();
        const operator = _.isNull(x) ?
          CriteriaOperator.IS_NULL :
          (typeof x === 'object' ?
            (Array.isArray(x) ? CriteriaOperator.IN : CriteriaOperator.IS) :
            CriteriaOperator.EQUAL);
        query.and(fieldName, operator, x);
        resolve(query);
      });
    });
  }

  public getGroupingReadyKey(data: any, fieldName: string): string {
    const value = this.getGroupingValue(data, fieldName);
    return Array.isArray(value) ? value.join(',') : value;
  }

  public async toggleDataTable(data: any, col: DatatableColumn): Promise<void> {
    const groupingType = this.groupingValues[this.getGroupingReadyKey(data, col.field)];
    groupingType.ready = !groupingType.ready;
    this.cdr.detectChanges();
    if (groupingType.ready && !groupingType.values?.length) {
      await this.loadInnerTable(data, col, this.groupingTypeToLazyLoadEvent(groupingType));
    }
  }

  public async loadInnerTable(data: any, col: DatatableColumn, $event?: LazyLoadEvent): Promise<void> {
    const groupingType = this.groupingValues[this.getGroupingReadyKey(data, col.field)];
    groupingType.loading = true;
    this.cdr.detectChanges();
    await this.getGroupList(data, col).load($event ?? {first: 0, rows: groupingType.rows});
    groupingType.loading = false;
    this.cdr.detectChanges();
  }

  public doRefresh($event: any): void {
    // prevent endless loop
    this.refresh(false, false);
  }

  /**
   * do a full table count
   */
  public countInner(groupingType: GroupingType): void {
    groupingType.query.clone().offset(0).limit(undefined).getRowCount().then(total => {
      groupingType.exactTotalRecords = groupingType.totalRecords = total;
      this.cdr.detectChanges();
    });
  }

  public onPageChange(groupingType: GroupingType, outerData: any, outerCol: DatatableColumn, $event: any): void {
    groupingType.first = $event.first;
    groupingType.rows = $event.rows;

    this.getGroupList(outerData, outerCol).load(this.groupingTypeToLazyLoadEvent(groupingType))
      .then((vals) => this.cdr.detectChanges());
  }

  public aggregateInner(gType: GroupingType, col: DatatableColumn): number {
    const agg = Utility.aggregate(col.field, col.aggregation, gType.values);
    return isNaN(agg) ? null : agg;
  }

  public enableEdit(groupingType: GroupingType, data: KolibriEntity): void {
    groupingType.editableRows.push(data.id);
  }

  public saveEdit(groupingType: GroupingType, data: KolibriEntity): void {
    this.table.saveRowEdit(data, this.table.el.nativeElement);
    this.onEditSave.emit(data);
    _.remove(groupingType.editableRows, id => id === data.id);
  }

  public cancelEdit(groupingType: GroupingType, data: KolibriEntity): void {
    this.onEditCancel.emit(data);
    _.remove(groupingType.editableRows, id => id === data.id);
  }

  public doSelectAll($event: boolean): void {
    this.selection = $event ? Object.values(this.groupingValues).filter(gt => gt.ready).flatMap(gt => gt.values) : [];
  }

  public getOuterRenderType(outerCol: DatatableColumn): RenderType {
    switch (outerCol.renderType) {
      case RenderType.PROGRESSBAR:
      case RenderType.STEPS:
        // ProgressBar and Steps should be rendered like no renderType is given in group header
        return null;
      default:
        return outerCol.renderType;
    }
  }

  private async setInternalRowCount(groupingType: GroupingType, $event: LazyLoadEvent, limit: number): Promise<void> {
    // reset the exact count when searching from scratch
    if ($event.first === 0 || !groupingType.exactTotalRecords) {
      groupingType.exactTotalRecords = null;
      const size = limit * 7;
      const count = await groupingType.query.clone().limit(size).getRowCount();
      groupingType.totalRecords = count + $event.first;
      // we did not get the full range, so there aren't more results
      if (count < size) {
        groupingType.exactTotalRecords = groupingType.totalRecords;
      }
      this.cdr.detectChanges();
    }
  }

  private groupingTypeToLazyLoadEvent(groupingType: GroupingType): LazyLoadEvent {
    const lazyLoadEvent = {
      first: groupingType.first ?? 0,
      rows: groupingType.rows ?? this.innerInitialRows
    } as LazyLoadEvent;
    if (groupingType.filters) {
      lazyLoadEvent.filters = groupingType.filters;
    }
    if (groupingType.multiSortMeta) {
      lazyLoadEvent.multiSortMeta = groupingType.multiSortMeta;
    }
    return lazyLoadEvent;
  };

  private createGroupingType(query: CriteriaQuery<KolibriEntity>, values: KolibriEntity[], totalRecords: number = 0,
                             oldGroupingType?: GroupingType): GroupingType {
    const groupingObj: GroupingType = {query, values, totalRecords, exactTotalRecords: totalRecords};
    groupingObj.loading = true;
    groupingObj.ready = false;
    groupingObj.multiSortMeta = this.multiSortMeta;
    groupingObj.rows = oldGroupingType?.rows ?? this.innerInitialRows;
    groupingObj.first = oldGroupingType?.first ?? 0;
    groupingObj.aggregationValue = {};
    groupingObj.editableRows = [];
    const self = this;
    Object.defineProperty(groupingObj, 'selection', {
      get(): KolibriEntity[] {
        return groupingObj.pSelection;
      },
      set(value: KolibriEntity[]): void {
        groupingObj.pSelection = value;
        if (self.selectionMode === 'multiple') {
          self.selection = Object.values(self.groupingValues).flatMap((v: GroupingType) => v.pSelection ?? []);
        }
      },
      enumerable: false,
      configurable: true
    });
    return groupingObj;
  }

  private getGroupingValue(data: any, fieldName: string): any {
    if (Utility.isRelation(this.modelService.getField(this.entityMeta.name, fieldName))) {
      if (Utility.isDotWalk(fieldName)) {
        fieldName = Utility.getDotWalkOrigin(fieldName);
      }
      return data[Utility.parameterizeEntityName(fieldName)];
    } else {
      return data[fieldName];
    }
  }

  private calculateColumns(): void {
    this.groupingColumns = this.columns.filter(col => col.field !== this.groupByField);
    this.groupByColumn = this.columns.find(col => col.field === this.groupByField);
  }

  private convertFilterValueToCriteriaValue(filterValue: any, col: DatatableColumn): any {
    switch (col.typeName) {
      case 'Choice':
        if (Array.isArray(filterValue)) {
          return filterValue.map(c => c.value);
        }
        return filterValue.value;
      case 'Date':
        return (filterValue as Date).toISOString();
      default:
        return filterValue;
    }
  }

  private convertDefaultFilterToCriteriaOperator(groupFilter: FilterMetadata): CriteriaOperator {
    const groupFilterValue = groupFilter.value;

    switch (groupFilter.matchMode) {
      case 'lt':
      case 'dateBefore':
        return CriteriaOperator.LESS;
      case 'gt':
      case 'dateAfter':
        return CriteriaOperator.GREATER;
      case 'lte':
        return CriteriaOperator.LESS_OR_EQUAL;
      case 'gte':
        return CriteriaOperator.GREATER_OR_EQUAL;
      case 'dateIsNot':
        return CriteriaOperator.IS_NOT;
      case 'endsWith':
        return CriteriaOperator.ENDS_WITH;
      case 'startsWith':
        return CriteriaOperator.BEGINS_WITH;
      case 'contains':
        return CriteriaOperator.CONTAINS;
      case 'notContains':
        return CriteriaOperator.NOT_CONTAINS;
      case 'equals':
        return CriteriaOperator.EQUAL;
      case 'notEquals':
        return CriteriaOperator.NOT_EQUAL;
      case 'dateIs':
      default:
        return typeof groupFilterValue === 'object' ?
          (Array.isArray(groupFilterValue) ? CriteriaOperator.IN : CriteriaOperator.IS) :
          CriteriaOperator.BEGINS_WITH;
    }
  }
}
