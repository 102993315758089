import {Directive, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {CriteriaOperator} from '@wspsoft/frontend-backend-common';
import {SelectItemGroup} from 'primeng/api';
import {AutoComplete as PrimeNgAutocomplete} from 'primeng/autocomplete';
import {Subscription} from 'rxjs';
import {UiUtil} from '../../../util/ui-util';
import {CustomInput} from '../custom-input';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
export abstract class AutoComplete<T, E = T> extends CustomInput<T> implements OnDestroy {
  @Input()
  public clearable: boolean = true;
  @Input()
  public size: number = 10;
  @Input()
  public readonly: boolean = true;
  @Input()
  public dropdown: boolean = true;
  @Input()
  public forceSelection: boolean = true;
  @Input()
  public displayFields: string[];
  @Input()
  public queryFields: string[];
  @Input()
  public searchOperator: CriteriaOperator;
  /**
   * display always a value
   */
  @Input()
  public defaultEntry: (query: string) => any;
  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter();
  @Output()
  public onShow: EventEmitter<any> = new EventEmitter();
  @Output()
  public onHide: EventEmitter<any> = new EventEmitter();
  @Output()
  public onUnselect: EventEmitter<any> = new EventEmitter();
  @ViewChild('nativeInput', {static: true})
  protected autocomplete: PrimeNgAutocomplete;
  protected subscription: Subscription;
  protected lastQuery: string;
  protected overlayPanel: any;
  protected queryOffset: number = 0;
  // the amount that gets added to the query offset on every load
  protected queryOffsetSteps: number = 30;
  protected checkScrollDebounced: () => Promise<void>;
  private scrollListenerBound: boolean = false;


  private psuggestions: E[] | SelectItemGroup[];
  @Input()
  public get suggestions(): E[] | SelectItemGroup[] {
    return this.psuggestions;
  }

  public set suggestions(value: E[] | SelectItemGroup[]) {
    this.psuggestions = value;
    if (!this.lastQuery) {
      this.doAutoSelect();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Sets the value to the only suggestion if there is only one, the autoselect boolean is set and its not a multiple
   * Emits the value after setting it.
   * Autocompletes can be grouped. The autoselect works as well if there is only one group and one item in that group.
   * In the case if there is no value and its forced to be not empty, the old value is used (if exists).
   * After setting the value, validate function is called.
   */
  public doAutoSelect(): void {
    if (this.psuggestions?.length === 1 && this.require && !this.multiple) {
      if (UiUtil.isSelectItemGroup(this.psuggestions[0])) {
        if ((this.psuggestions as SelectItemGroup[])[0].items?.length === 1) {
          this.value = (this.psuggestions as SelectItemGroup[])[0].items[0] as any;
          this.onSelect.emit(this.value);
        }
      } else {
        // suggestions[0] should be always from type T
        // @ts-ignore
        this.value = this.psuggestions[0];
        this.onSelect.emit(this.value);
      }
    }
  }

  public clear(): void {
    // primeng triggers this way too often, on multiple fields setting the value to null breaks the autocomplete
    // that's why we need to skip it here
    if (!this.multiple) {
      this.value = null;
    }
  }

  public calculateSuggestions($event: any): void | Promise<void> {

  }

  protected restoreOldValue(): void {
    this.value = this.oldValue;
  }

  /**
   * Binds a scroll listener to the Autocomplete Overlay.
   * If this function is used remember to also bind the removelistenerAndReset Method and define the calculateSuggestions method
   * @returns {Promise<void>}
   * @protected
   */
  protected async bindScrollListener(): Promise<void> {
    this.overlayPanel = await this.autocomplete.overlayViewChild?.contentViewChild?.nativeElement?.children[0];
    if (this.overlayPanel && !this.scrollListenerBound) {
      this.overlayPanel.addEventListener('scroll', this.checkScrollDebounced.bind(this));
      this.scrollListenerBound = true;
    }
  }

  protected removeScrollListenerAndReset(): void {
    this.overlayPanel?.removeEventListener('scroll', this.checkScrollDebounced.bind(this));
    this.queryOffset = 0;
  }

  /**
   * Checks the current scroll position of the Overlay and checks if more suggestions need to be loaded.
   * @returns {Promise<void>}
   * @protected
   */
  protected async checkScroll(): Promise<void> {
    // scrollTop = distance that has been scrolled
    // scrollHeight = the full scrollable height, including hidden content
    // offsetHeight = the height of the Overlay
    if (this.overlayPanel && (this.overlayPanel.scrollTop >= (this.overlayPanel.scrollHeight - this.overlayPanel.offsetHeight))) {
      this.queryOffset += this.queryOffset !== 0 ? this.queryOffsetSteps : this.size;
      await this.calculateSuggestions({query: this.lastQuery});
    }
  }

  /**
   * primeng is stupid and sets the value to an empty string if the ac is cleared with backspace
   */
  protected setValue(value: T, emitChange: boolean = true): void {
    // @ts-ignore
    if (value === '') {
      value = null;
    }

    super.setValue(value, emitChange);
  }
}
