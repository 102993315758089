import {GroupMembership} from './group-membership';
import {KolibriEntity} from './kolibri-entity';
import {RoleAssignment} from './role-assignment';
import {User} from './user';

export class UserGroup extends KolibriEntity {
  public name: string;
  public manager?: User;
  public managerId?: string;
  public email?: string;
  public roleAssignments?: RoleAssignment[];
  public groupMemberships?: GroupMembership[];
}
