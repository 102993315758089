export class Constants {
  public static readonly SEARCH: string = 'SEARCH';
  public static readonly READ: string = 'READ';
  public static readonly CREATE: string = 'CREATE';
  public static readonly DELETE: string = 'DELETE';
  public static readonly UPDATE: string = 'UPDATE';
  public static readonly DEFAULT_OPERATIONS: string[] = [
    Constants.READ, Constants.CREATE, Constants.DELETE, Constants.UPDATE
  ];

  public static readonly SHARE_PRIVATE: string = 'SHARE_PRIVATE';
  public static readonly SHARE_PUBLIC: string = 'SHARE_PUBLIC';
  public static readonly SHARE_USERGROUP: string = 'SHARE_USERGROUP';
  public static readonly SHARE_ROLE: string = 'SHARE_ROLE';
  public static readonly DEFAULT_SHARE_OPERATIONS: string[] = [
    Constants.SHARE_PRIVATE, Constants.SHARE_PUBLIC, Constants.SHARE_USERGROUP, Constants.SHARE_ROLE
  ];
}
