import {_, MaybePromise} from '@wspsoft/underscore';
import {
  AbstractEntityServiceFactory,
  Attribute,
  Button,
  DateType,
  DesignerEntity,
  DisplayTransformation,
  GraphNodeTemplate,
  GuidedTour,
  GuidedTourStep,
  Layout,
  LayoutGraph,
  LayoutSection,
  MenuItem,
  Relation,
  Wizard,
  WizardToWizardSection
} from '../..';

import {TranslateService} from '../../api/abstract-kolibri-translate';
import {CriteriaOperator} from '../../criteria/criteria-operator';
import {CriteriaOrder} from '../../criteria/criteria-order';
import {DateUnit} from '../../criteria/json/date-range';
import {Field} from '../../model/response/field';

import {ChoiceValue, Entity} from '../../model/xml/models';

import {BundleKeyGenerator} from '../../util/bundle-key-generator';

import {MomentUtil} from '../../util/moment-util';

import {Utility} from '../../util/utility';

import {AbstractModelService} from '../coded/abstract-model.service';

import {AbstractRuntimeModelService} from '../coded/abstract-runtime-model.service';

export abstract class AbstractModelTranslationService {
  protected constructor(protected translateService: TranslateService, protected modelService: AbstractModelService,
                        private entityServiceFactory: AbstractEntityServiceFactory) {
  }

  /**
   * convert designer entity to its translation key
   */
  public getDesignerEntityKey(entity: DesignerEntity, parent?: DesignerEntity): string {
    switch (entity.entityClass) {
      case 'Attribute':
      case 'Relation':
        const field = entity as Field;
        return BundleKeyGenerator.fieldToKey(field, this.modelService.getEntity(field.entityId));
      case 'Button':
        const button = entity as Button;
        return BundleKeyGenerator.buttonToKey(parent ?? this.modelService.getEntity(button.entityId), button);
      case 'ChoiceValue':
        const choiceValue = entity as ChoiceValue;
        return BundleKeyGenerator.choiceValueToKey(this.modelService.getChoice(choiceValue.choiceId), choiceValue);
      case 'Entity':
        return BundleKeyGenerator.entityToKey(entity);
      case 'GraphNodeTemplate':
        const gnt = entity as GraphNodeTemplate;
        const layoutOfGnt = this.modelService.getLayout(gnt.graphId, gnt.entityId) as LayoutGraph;
        const entityOfGnt = parent ?? this.modelService.getEntity(layoutOfGnt.entityId);
        return BundleKeyGenerator.nodeTemplateToKey(entityOfGnt, layoutOfGnt, gnt);
      case 'GuidedTour':
        return BundleKeyGenerator.tourToKey(entity as GuidedTour);
      case 'GuidedTourStep':
        return BundleKeyGenerator.stepToKey(entity as GuidedTourStep);
      case 'LayoutForm':
      case 'LayoutList':
      case 'LayoutGraph':
      case 'LayoutMap':
      case 'LayoutTree':
      case 'LayoutDataView':
      case 'LayoutFullCalendar':
        const layout = entity as Layout;
        return BundleKeyGenerator.layoutToKey(parent ?? this.modelService.getEntity(layout.entityId), layout);
      case 'LayoutSection':
      case 'AttachmentLayoutSection':
      case 'NestedLayoutSection':
      case 'RelatedListLayoutSection':
      case 'StandardLayoutSection':
      case 'TabLayoutSection':
      case 'VariablesLayoutSection':
      case 'WidgetLayoutSection':
        const area = entity as LayoutSection;
        return BundleKeyGenerator.areaToKey(this.modelService.getEntity(area.entityId), area);
      case 'MenuItem':
        return BundleKeyGenerator.menuItemToKey(entity as MenuItem);
      case 'Wizard':
        return BundleKeyGenerator.wizardToKey(entity as Wizard);
      case 'WizardToWizardSection':
        const w2w = entity as WizardToWizardSection;
        return BundleKeyGenerator.wizardSectionToKey(w2w.wizard, w2w.wizardSection);
      default:
        console.warn(`No Translation key case for ${entity.entityClass}`);
    }
  }

  public translateOperator(operator: CriteriaOperator, lang?: string): string {
    return this.translateService.instant('QueryBuilder.Rule.Operator.' + CriteriaOperator[operator], lang);
  }

  public translateOrder(operator: CriteriaOrder, lang?: string): string {
    return this.translateService.instant('QueryBuilder.Sort.Order.' + CriteriaOrder[operator], lang);
  }

  public translateDateUnit(operator: DateUnit, lang?: string): string {
    return this.translateService.instant('DateRange.Unit.' + DateUnit[operator], lang);
  }

  public translateChoice(choiceName: string, typeValue: string, lang?: string): string {
    const choiceValue = this.modelService.getChoiceValue(choiceName, typeValue);

    if (!choiceValue) {
      return '';
    }

    return this.translateChoiceValue(choiceValue, choiceName, lang);
  }

  public translateChoiceValue(value: ChoiceValue, choiceName: string, lang?: string): string {
    const choiceMeta = this.modelService.getChoice(choiceName);
    return this.translateService.instant(BundleKeyGenerator.choiceValueToKey(choiceMeta, value), lang);
  }

  public translateDotWalkField(entityMeta: Entity, s: string, lang?: string): string {
    const dotWalks = s.split('.');
    // try to find the one with the exact same name
    const label = [];
    const currentDotWalk = [];

    // we might have something like createdBy.createdBy.active, but we need Erstellt von.Erstellt von.Aktiv
    // so translate every sub field
    for (const x of dotWalks) {
      currentDotWalk.push(x);
      const fieldResponse = this.modelService.getFields(entityMeta.id, currentDotWalk.join('.'));
      // should be exactly one field
      label.push(this.translateField(fieldResponse.entity, fieldResponse.fields[0], lang));
    }

    return label.join('.');
  }

  public translateField(entity: Entity, field: Field, lang?: string): string {
    const key = BundleKeyGenerator.fieldToKey(field, entity);
    const translation = this.translateService.instant(key, lang);
    return translation === key ? field.name : translation;
  }

  public translateEntity(entity: Entity, lang?: string): string {
    return this.translateService.instant(BundleKeyGenerator.entityToKey(entity), lang);
  }

  public translateLayout(entity: Entity, layout: Layout, lang?: string): string {
    return this.translateService.instant(BundleKeyGenerator.layoutToKey(entity, layout), lang);
  }

  /**
   *  gets entity from model service by given name and translates it accordingly
   * @param {string} name of entity to translate
   * @returns {string} returns translated entity name
   */
  public translateEntityByName(name: string): string {
    const entity = this.modelService.getEntity(name);
    return this.translateEntity(entity);
  }

  public translateObjectValue(data: any[], field: string, lang: string, meta?: Field, translateNull?: boolean, sync?: boolean,
                              timezone?: string, useDisplayTransformation?: boolean): MaybePromise<string[]>;
  public translateObjectValue(data: any, field: string, lang: string, meta?: Field, translateNull?: boolean, sync?: boolean,
                              timezone?: string, useDisplayTransformation?: boolean): MaybePromise<string>;
  public translateObjectValue(data: any | any[], field: string, lang: string, meta?: Field, translateNull: boolean = false,
                              sync: boolean = false, timezone?: string, useDisplayTransformation: boolean = true): MaybePromise<string | string[]> {
    if (!data) {
      return '';
    }
    if (!meta && data.entityClass) {
      const entityByName = this.modelService.getEntity(data.entityClass);
      meta = entityByName ? this.modelService.getField(entityByName.id, field) : null;
    }

    let value;
    if (Utility.isDotWalk(field) || _.isPromise(data[field])) {
      // read bulk loaded field with initial dotwalk (e.g. (createdBy)//.username) then the actual field
      value = data[Utility.wordifyDotWalk(field, true)];
      if (value && !_.isPromise(value)) {
        if (value.entityClass && value.entityClass !== data.entityClass) {
          // if the field is overridden by a descending entity the meta must be edited if not use existing
          meta = this.modelService.getField(value.entityClass, Utility.getDotWalkTarget(field));
        }
        value = value[Utility.getDotWalkTarget(field)];
      }

      // data is not yet loaded, or dotwalk was target of another relation
      if (!value || _.isPromise(value)) {
        if (!sync) {
          return new Promise(resolve => {
            Utility.doDotWalk(data, field, x => {
              resolve(this.translateValue(x, meta, translateNull, lang, timezone, useDisplayTransformation));
            });
          });
        } else {
          const x = Utility.dotWalkWithSuffix(data, field);
          return this.translateValue(x, meta, translateNull, lang, timezone, useDisplayTransformation);
        }
      }
    } else {
      value = data[field];
    }
    return this.translateValue(value, meta, translateNull, lang, timezone, useDisplayTransformation);
  }

  /**
   * translates a basic value from an entity field
   */
  public translateFieldValue(value: any[], entity: string, field: string, translateNull: boolean, lang: string, timezone?: string,
                             useDisplayTransformation?: boolean): MaybePromise<string[]>;
  public translateFieldValue(value: any, entity: string, field: string, translateNull: boolean, lang: string, timezone?: string,
                             useDisplayTransformation?: boolean): MaybePromise<string>;
  public translateFieldValue(value: any | string[], entity: string, field: string, translateNull: boolean = false, lang: string, timezone?: string,
                             useDisplayTransformation: boolean = true): MaybePromise<string | string[]> {
    const entityByName = this.modelService.getEntity(entity);
    const meta = this.modelService.getField(entityByName.id, field);
    return this.translateValue(value, meta, translateNull, lang, timezone, useDisplayTransformation);
  }

  public translateValue(value: any[], meta: Field, translateNull: boolean, lang: string, timezone?: string,
                        useDisplayTransformation?: boolean): MaybePromise<string[]>;
  public translateValue(value: any, meta: Field, translateNull: boolean, lang: string, timezone?: string,
                        useDisplayTransformation?: boolean): MaybePromise<string>;
  public translateValue(value: any | any[], meta: Field, translateNull: boolean = false, language: string, timezone?: string,
                        useDisplayTransformation: boolean = true): MaybePromise<string | string[]> {
    if ((value === null || value === undefined) && translateNull) {
      return this.translateService.instant('RawData.null', language);
    }

    const attribute = meta as Attribute;
    let transform: DisplayTransformation;
    if (attribute && useDisplayTransformation) {
      transform = this.modelService.getDisplayTransformation(attribute?.displayTransformId);
    }

    if (Array.isArray(value)) {
      return value.map(v => this.translateValue(v, meta, translateNull, language, timezone, useDisplayTransformation) as string);
    }

    switch (this.modelService.getTypeName(meta)) {
      case 'Boolean':
        return this.translateService.instant(
          'RawData.' + value || 'false', language);
      case 'Date':
        switch (transform?.dateType) {
          case DateType.TIME:
            return MomentUtil.timeToString(value, {timezone, language});
          case DateType.DATETIME:
            return MomentUtil.datetimeToString(value, {timezone, language});
          default:
            return MomentUtil.dateToString(value, {timezone, language});
        }
      case 'I18n':
        // in the frontend the i18n is auto resolved to the current language
        return typeof value === 'string' ? value : value?.[language];
      case 'Number':
        if (!_.isNull(value)) {
          return (transform?.symbol ? (transform?.symbol + ' ') : '') + new Intl.NumberFormat(language, {
            minimumFractionDigits: transform?.decimalPlaces,
            maximumFractionDigits: transform?.decimalPlaces,
          }).format(value);
        } else {
          return value;
        }
      case AbstractRuntimeModelService.CHOICE:
        return this.translateChoice(attribute.typeId, value, language);
      case AbstractRuntimeModelService.KOLIBRI_ENTITY:
      case AbstractRuntimeModelService.KOLIBRI_ENTITY_ARRAY:
        return this.translateEntityValue(value, meta);
      default:
        return value;
    }
  }

  public translateEntityValue(value?: any, field?: Relation): MaybePromise<string> {
    if (typeof value === 'string') {
      return this.translateEntityById(field, value);
    }

    return value ? value.representativeString : '';
  }

  /**
   * translate relation target with id
   */
  private async translateEntityById(field: Relation, value: string): Promise<string> {
    return (await this.entityServiceFactory.getService(field.targetId).getEntityById(value))?.representativeString || '';
  }
}
