import {TranslateService} from '@ngx-translate/core';
import {
  AggregationFunction,
  BundleKeyGenerator,
  CriteriaFunction,
  CriteriaQuery,
  CriteriaQueryGroup,
  EntityModel,
  KolibriEntity
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {ModelService, TypeService} from '../../../api';
import {DatatableColumn} from '../components/structure/datatable/datatable/datatable.component';


export abstract class ListUtil {
  /**
   * handle a single prime ng column filter and adds it to the query
   */
  public static applyColumnFilter($event: LazyLoadEvent, group: CriteriaQueryGroup<KolibriEntity>, columns: DatatableColumn[], typeUtility: TypeService): void {
    if ($event.filters) {
      for (const key of Object.keys($event.filters)) {
        const filter = $event.filters[key];

        // skip empty strings
        if (_.isNull(filter.value) || filter.value === '' || key === 'global') {
          continue;
        }
        const column = _.find(columns, {field: key});
        typeUtility.addFilter(group, column, filter, false);
      }
    }
  }

  public static applySortMeta($event: LazyLoadEvent, query: CriteriaQuery<KolibriEntity>, columns: DatatableColumn[], typeUtility: TypeService): void {
    if ($event.multiSortMeta) {
      for (const sortMeta1 of $event.multiSortMeta) {
        const column = _.find(columns, {field: sortMeta1.field});
        typeUtility.addOrder(sortMeta1, column?.meta, query);
      }
    }
  }

  public static throwNoColumnMessage(fieldName: string, entityMeta: EntityModel, modelService: ModelService,
                                     translate: TranslateService, messageService: MessageService): void {
    const field = modelService.getField(entityMeta.name, fieldName);
    const translatedField = translate.instant(BundleKeyGenerator.fieldToKey(field, entityMeta));
    messageService.add({
      severity: 'error',
      detail: translate.instant('Datatable.GroupBy.NoColumn', {fieldName: translatedField}),
      summary: '',
      key: 'growl',
      sticky: false
    });
  }

  public static async calculateFullAggregation(aggregation: AggregationFunction, query: CriteriaQuery<KolibriEntity>,
                                               showAggregation: boolean): Promise<number> {
    if (aggregation && showAggregation) {
      const aggregationQuery = query.clone();
      switch (aggregation) {
        case AggregationFunction.COUNT:
          const resultCount = await aggregationQuery.limit(undefined)
            .offset(0)
            .addTransform('order', 'count')
            .addSelectField('count', CriteriaFunction.COUNT)
            .addGroupBy('groupBySomeField')
            .execute();
          return resultCount[0]?.count;
        case AggregationFunction.AVG:
          const resultAvg = await aggregationQuery.limit(undefined)
            .offset(0)
            .addTransform('order', 'avg')
            .addSelectField('avg', CriteriaFunction.AVG)
            .addGroupBy('groupBySomeField')
            .execute();
          return resultAvg[0]?.avg;
        case AggregationFunction.SUM:
          const resultSum = await aggregationQuery.limit(undefined)
            .offset(0)
            .addTransform('order', 'sum')
            .addSelectField('sum', CriteriaFunction.SUM)
            .addGroupBy('groupBySomeField')
            .execute();
          return resultSum[0]?.sum;
      }
    }
    return;
  }
}
