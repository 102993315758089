import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CriteriaOperator, CriteriaQuery, Entity, KolibriEntity, Relation, RelationInfo} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import * as uuidv4 from 'uuid-random';
import {CriteriaFactory, EntityService, EntityServiceFactory, ModelService} from '../../../../../../api';
import {RelationService} from '../../../../../../api/app/service/util/relation.service';
import {RedirectorService} from '../../../../service/redirector.service';
import {KolibriEntityConverterService} from '../../../converter/kolibri-entity-converter.service';
import {AutoComplete} from '../autocomplete';


@Component({
  selector: 'ui-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './multi-select-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectAutocompleteInputComponent),
    multi: true
  }, {
    provide: KolibriEntityConverterService
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectAutocompleteInputComponent extends AutoComplete<KolibriEntity[], KolibriEntity> implements OnInit, OnDestroy {
  @Input()
  public entityId: string;
  public entityMeta: Entity;
  public mappingEntity: Entity;
  @Input()
  public parentEntityId: string;
  public parentEntityMeta: Entity;
  @Input()
  public search: (result: CriteriaQuery<KolibriEntity>, queryString?: string) => MaybePromise<any>;
  @Input()
  public convertToString: boolean = false;
  @Input()
  public ignoreMappingEntity: boolean = false;
  @Input()
  public currentRecord: KolibriEntity;
  @Input()
  public fieldId: string;
  @Input()
  public toInsert: KolibriEntity[];
  @Input()
  public toDelete: KolibriEntity[];
  @Output()
  private toInsertChange: EventEmitter<KolibriEntity[]> = new EventEmitter<KolibriEntity[]>();
  @Output()
  private toDeleteChange: EventEmitter<KolibriEntity[]> = new EventEmitter<KolibriEntity[]>();
  private isMappingEntity: boolean;
  private mappingService: EntityService<KolibriEntity>;
  private relationInfo: RelationInfo;
  private newMapping: KolibriEntity;

  public constructor(private entityConverter: KolibriEntityConverterService, private redirectorService: RedirectorService,
                     private entityServiceFactory: EntityServiceFactory, private relationService: RelationService,
                     private modelService: ModelService, private criteriaFactory: CriteriaFactory,
                     cdr: ChangeDetectorRef) {
    super(cdr);
    this.multiple = true;
    this.converter = entityConverter;
    this.checkScrollDebounced = _.debounce(this.checkScroll, 50);
  }

  private ptargetValue: KolibriEntity[];

  @Input()
  public get targetValue(): KolibriEntity[] {
    if (this.convertToString) {
      return this.value;
    } else {
      return this.ptargetValue;
    }
  }

  public set targetValue(value: KolibriEntity[]) {
    if (this.convertToString) {
      this.value = value;
    } else {
      const removedValues = _.difference(this.ptargetValue, value);
      const addedValues = _.difference(value, this.ptargetValue);

      if (this.isMappingEntity) {
        for (const removedValue of removedValues) {
          const m2m = _.find(this.value, {[this.relationInfo.destinationRelation.name + 'Id']: removedValue.id});
          if (m2m.persisted) {
            this.toDelete.push(m2m);
          }
          this.value = [..._.filter(this.value, (v) => v.id !== m2m.id)];

          _.remove(this.toInsert, {id: m2m.id});
        }

        const newValues = [];
        for (const addedValue of addedValues) {
          const wasAlreadyPersisted = _.find(this.toDelete, {[this.relationInfo.destinationRelation.name + 'Id']: addedValue.id});
          if (wasAlreadyPersisted) {
            newValues.push(wasAlreadyPersisted);

            _.remove(this.toDelete, {[this.relationInfo.destinationRelation.name + 'Id']: addedValue.id});
          } else {
            const mapping = this.newMapping.copy();
            mapping.id = uuidv4();
            mapping[this.relationInfo.destinationRelation.name] = addedValue;
            mapping[this.relationInfo.sourceRelation.name] = this.currentRecord;
            if (!this.currentRecord.persisted) {
              this.defineIdGetter(mapping, this.relationInfo.sourceRelation.name);
            }

            newValues.push(mapping);
            this.toInsert.push(mapping);
          }
        }
        this.value = [...this.value, ...newValues];
      } else {
        for (const removedValue of removedValues) {
          removedValue[this.relationInfo.parentRelation.targetRelationName] = null;

          // only add it to toDelete if the value was already attached to record
          if (!_.some(this.toInsert, {id: removedValue.id})) {
            this.toDelete.push(removedValue);
          }

          _.remove(this.toInsert, {id: removedValue.id});
        }

        for (const addedValue of addedValues) {
          addedValue[this.relationInfo.parentRelation.targetRelationName] = this.currentRecord;
          if (!this.currentRecord.persisted) {
            this.defineIdGetter(addedValue, this.relationInfo.parentRelation.targetRelationName);
          }

          // only add it to toInsert if the value was not already attached to record
          if (!_.some(this.toDelete, {id: addedValue.id})) {
            this.toInsert.push(addedValue);
          }

          _.remove(this.toDelete, {id: addedValue.id});
        }
        this.value = [...value];
      }

      this.ptargetValue = value;
      this.toInsertChange.emit(this.toInsert);
      this.toDeleteChange.emit(this.toDelete);
    }
  }

  private get ownRelation(): Relation {
    const relations: Relation[] = this.modelService.getRelations(this.entityMeta.id);
    return _.find(relations, {targetRelationName: this.relationInfo.destinationRelation.name, targetId: this.relationInfo.parentRelation.targetId});
  }

  public async loadValues(): Promise<void> {
    // if it loads to initiate and current record is not set, then load it from value
    if (!this.currentRecord) {
      return;
    }

    // if one of the current values is not yet persisted, we set is with emitChange = false to avoid an extra change detection
    this.setValue(await this.getResults(false), !_.some(this.value, {persisted: false}));

    if (this.isMappingEntity && !this.convertToString) {
      // create mappingEntity shell to avoid constant db calls for new m2m shell
      this.newMapping = await this.entityServiceFactory.getService(this.modelService.getEntity(this.relationInfo.parentRelation.targetId).name).getNewEntity();
      this.ptargetValue = await this.mappingService.getEntityRelations(this.value, this.relationInfo.destinationRelation.name);
    } else {
      this.ptargetValue = [...this.value]; // "clone" array
    }

    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.entityConverter.convertToString = this.convertToString;
    this.entityConverter.entityNameOrId = this.entityId;

    this.entityMeta = this.modelService.getEntity(this.entityId);
    this.parentEntityMeta = this.modelService.getEntity(this.parentEntityId);

    this.relationInfo = this.relationService.findInverseRelations(this.fieldId, this.parentEntityMeta, this.entityMeta);

    if (this.entityMeta.mappingEntity && !this.ignoreMappingEntity) {
      this.isMappingEntity = true;
      const {destinationRelation} = this.relationInfo;
      this.mappingEntity = this.entityMeta;
      this.mappingService = this.entityServiceFactory.getService(this.mappingEntity.name);
      this.entityMeta = this.modelService.getEntityByType(destinationRelation);
      // the converter must load from destinationEntity and not from mapping
      this.entityConverter.entityNameOrId = this.entityMeta.id;
    }

    void this.loadValues();
  }

  public async onComplete($event: any): Promise<void> {
    await this.calculateSuggestions($event);
    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
  }

  public async calculateSuggestions($event: any): Promise<void> {
    if (this.lastQuery !== $event.query) {
      this.queryOffset = 0;
    }
    this.lastQuery = $event.query;
    const results = await this.getResults(true, $event.query, true);
    // if we do not have an offset we can just use the results. Otherwise, we need to append the query results to the already fetched results
    this.suggestions = this.queryOffset !== 0 ? [...this.suggestions as KolibriEntity[], ...results] : results;

    if (!this.suggestions.length && this.defaultEntry) {
      this.suggestions = [this.defaultEntry($event.query)];
    }

    this.cdr.detectChanges();
  }

  public openRecord(entity: KolibriEntity, $event: MouseEvent): Promise<boolean> {
    return this.redirectorService.redirectToEntity(entity, $event);
  }

  /*
   * in case the currentRecord isn't persisted, the id is not set at this point
   * with this we ensure the relation id field is set when the currentRecord is saved later on
   */
  private defineIdGetter(mapping: KolibriEntity, relationName: string): void {
    Object.defineProperty(mapping, relationName + 'Id', {
      get: () => mapping[relationName].id,
      set: (v) => mapping[relationName].id = v,
      enumerable: true
    });
  }

  private async getResults(forComplete: boolean, queryString?: string, includeOrder: boolean = false): Promise<KolibriEntity[]> {
    let query: CriteriaQuery<KolibriEntity>;

    // exclude things that are already marked as added
    if (forComplete) {
      query = this.criteriaFactory.getFrontendQuery(this.entityMeta.name);

      if (this.relationInfo.destinationRelation) {
        query.descendants(this.relationInfo.destinationRelation.descendants);
      } else {
        query.descendants(this.relationInfo.parentRelation.descendants);
      }

      if (this.search) {
        const result = this.search(query, queryString);
        if (_.isPromise(result)) {
          await result;
        }
      }
      const textFilter = query.addGroup();
      // if value is undefined use en empty array instead to get results
      query.addCondition('id', CriteriaOperator.NOT_IN,
        (this.targetValue || []).map(x => x.id));
      textFilter.addPercentBasedCondition('representativeString', queryString, undefined, this.searchOperator);
      for (const field of this.queryFields || []) {
        textFilter.addPercentBasedCondition(field, queryString, true, this.searchOperator);
      }
    } else {
      query = this.criteriaFactory.getFrontendQuery(this.isMappingEntity ? this.mappingEntity.name : this.entityMeta.name);

      const group = query.addGroup();
      let columnName: string;
      if (this.isMappingEntity) {
        // for m2m on the right side, avoid the join
        columnName = this.isMappingEntity ? this.relationInfo.sourceRelation.name : this.ownRelation.name + '.' + this.relationInfo.sourceRelation.name;
        if (!this.isMappingEntity) {
          query.descendants(this.relationInfo.destinationRelation.descendants);
        }
      } else {
        columnName = this.relationInfo.parentRelation.targetRelationName;
        query.descendants(this.relationInfo.parentRelation.descendants);
      }
      group.addCondition(columnName, CriteriaOperator.IS, this.currentRecord, true);
      group.addCondition(columnName, CriteriaOperator.IS_NOT_NULL, undefined, false);
    }

    // if we don't have a queryOffset we query the whole size otherwise we just need to query the next 5 records
    query.limit(this.queryOffset !== 0 ? this.queryOffsetSteps : this.size)
      .offset(this.queryOffset);

    // we need to exclude the order for retrieving the actual results that are already selected,
    // otherwise the lazy-loader would detect a change and trigger the onChange trigger
    if (includeOrder) {
      query.addOrder('representativeString');
    }

    return query.getResults();
  }
}
