import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {
  BundleKeyGenerator,
  CodeEditorLanguages,
  CodeEditorOptions,
  ConditionBuilderOptions,
  CriteriaOperator,
  CriteriaQueryGroupJson,
  CriteriaQueryJson,
  CriteriaType,
  EntityModel,
  Field,
  Utility
} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {CriteriaFactory, ModelService} from '../../../../../../api';
import {PortalUtil} from '../../../../../../portal/app/util/portal-util';
import {QueryOperator} from '../../../../entities/query-operator';
import {OneDialogService} from '../../../../service/one-dialog.service';
import {Converter} from '../../../converter/converter';
import {DatatableColumn} from '../../../structure/datatable/datatable/datatable.component';
import {CustomWriter} from '../../custom-writer';
import {QueryBuilderDataService} from './query-builder-data.service';

@Component({
  selector: 'ui-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{
    provide: ControlContainer,
    useValue: null
  }],
  providers: [{provide: QueryBuilderDataService}]
})
export class QueryBuilderComponent extends CustomWriter<CriteriaQueryJson | CriteriaQueryJson[]> implements OnInit, OnChanges {
  public BundleKeyGenerator: typeof BundleKeyGenerator = BundleKeyGenerator;
  @Input()
  public dialog: boolean = false;
  @Input()
  public breadcrumbOnly: boolean = false;
  @Input()
  public entityName: string;
  @Input()
  public recordEntity: string;
  @Input()
  public breadcrumb: boolean;
  @Input()
  public isMultipleChild: boolean;
  @Input()
  public formId: string;
  @Input()
  public multiple: boolean;
  @Input()
  public entityMetas: EntityModel[];
  @Input()
  public dialogDisplay: boolean;
  @Output()
  public onExecute: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onGroupByFieldChange: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public showOrder: boolean = false;
  @Input()
  public showQuery: boolean = true;
  @Input()
  public showGrouping: boolean = false;
  @Input()
  public groupByField: string;
  @Input()
  public availableColumns: DatatableColumn[];
  @Input()
  public disable: boolean;
  public loaded: boolean;
  public columnResult: DatatableColumn[] = [];

  public constructor(private modelService: ModelService, private criteriaFactory: CriteriaFactory,
                     private dialogService: OneDialogService,
                     private queryBuilderDataService: QueryBuilderDataService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  @Input()
  public get search(): (result: Field[], queryString?: string) => MaybePromise<Field[]> {
    return this.queryBuilderDataService.search;
  }

  public set search(value: (result: Field[], queryString?: string) => MaybePromise<Field[]>) {
    this.queryBuilderDataService.search = value;
  }

  @Input()
  public get codeEditorOptions(): CodeEditorOptions[] {
    return this.queryBuilderDataService.codeEditorOptions;
  }

  public set codeEditorOptions(value: CodeEditorOptions[]) {
    this.queryBuilderDataService.codeEditorOptions = value;
  }

  @Input()
  public get scriptLanguage(): CodeEditorLanguages {
    return this.queryBuilderDataService.scriptLanguage;
  }

  public set scriptLanguage(value: CodeEditorLanguages) {
    this.queryBuilderDataService.scriptLanguage = value;
  }

  @Input()
  public get additionalOperators(): QueryOperator[] {
    return this.queryBuilderDataService.additionalOperators;
  }

  public set additionalOperators(value: QueryOperator[]) {
    this.queryBuilderDataService.additionalOperators = value;
  }

  @Input()
  public get allowDotWalk(): boolean {
    return this.queryBuilderDataService.allowDotWalk;
  }

  public set allowDotWalk(value: boolean) {
    this.queryBuilderDataService.allowDotWalk = value;
  }

  @Input()
  public get additionalFields(): (Field & { validOperators: (CriteriaOperator)[] })[] {
    return this.queryBuilderDataService.additionalFields;
  }

  public set additionalFields(value: (Field & { validOperators: (CriteriaOperator)[] })[]) {
    this.queryBuilderDataService.additionalFields = value;
  }

  public get entityMeta(): EntityModel {
    return this.queryBuilderDataService.entityMeta;
  }

  public set entityMeta(value: EntityModel) {
    this.queryBuilderDataService.entityMeta = value;
  }

  @Input()
  public get localized(): boolean {
    return this.queryBuilderDataService.localized ?? true;
  }

  public set localized(value: boolean) {
    this.queryBuilderDataService.localized = value;
  }

  @Input()
  public get allowScriptedValue(): boolean {
    return this.queryBuilderDataService.allowScriptedValue;
  }

  public set allowScriptedValue(value: boolean) {
    this.queryBuilderDataService.allowScriptedValue = value;
  }

  @Input()
  public get allowScriptedRule(): boolean {
    return this.queryBuilderDataService.allowScriptedRule;
  }

  public set allowScriptedRule(value: boolean) {
    this.queryBuilderDataService.allowScriptedRule = value;
  }

  @Input()
  public get conditionBuilderOptions(): ConditionBuilderOptions[] {
    return this.queryBuilderDataService.conditionBuilderOptions;
  }

  public set conditionBuilderOptions(value: ConditionBuilderOptions[]) {
    this.queryBuilderDataService.conditionBuilderOptions = value;
  }

  public get columnConverter(): Converter<DatatableColumn, string> {
    return {
      getAsString: (value: DatatableColumn) => {
        if (value) {
          return value.field;
        }
        return null;
      },
      getAsObject: (s: string) => _.find(this.availableColumns, (col) => col.field === s)
    };
  }

  public get groupByFieldValue(): string {
    return this.groupByField;
  }

  public set groupByFieldValue(value: string) {
    this.groupByField = value;
  }

  public setRecordEntity(value: string): void {
    this.queryBuilderDataService.recordEntity = value;
  }

  public ngOnInit(): void {
    this.init();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // isEmpty is here because the value will not be cleared if it was empty and the entity will be set
    if (this.entityName && !this.multiple && !_.isEmpty(this.value)) {
      (this.value as CriteriaQueryJson).entity = this.entityName;
    }
    // execute when changed after first init
    if (changes.entityName && !changes.entityName.firstChange) {
      // entity changed, drop whole query
      this.clear();
    }
    if (changes.valueBinding && !changes.valueBinding.firstChange ||
      changes.entityName && !changes.entityName.firstChange) {
      // entity changed, drop whole query
      this.init();
    }
  }

  public clear(): void {
    if (this.multiple) {
      const value = [];
      for (const entityMeta of this.entityMetas) {
        // init empty query
        const query = this.criteriaFactory.getFrontendQuery(entityMeta.name, CriteriaType.SELECT);
        value.push(query.getJson());
      }
      this.value = value;
    } else {
      // init empty query
      const query = this.criteriaFactory.getFrontendQuery(this.entityMeta.name, CriteriaType.SELECT);
      this.value = query.getJson();
    }
    this.groupByFieldValue = null;
  }

  public run(): void {
    this.onGroupByFieldChange.emit(this.groupByField);
    this.onExecute.emit();
    this.close();
  }

  public cancel(): void {
    this.value = undefined;
    this.groupByFieldValue = undefined;
    this.close();
  }

  public close(): void {
    this.dialogService.get(`${this.formId}QueryBuilder`).close();
    this.value = _.cloneDeep(this.value);
    this.cdr.detectChanges();
  }

  public open(): void {
    this.dialogService.show(`${this.formId}QueryBuilder`);
    this.loaded = false;
    this.init();
  }

  public searchColumns($event: any): void {
    this.columnResult = _.filter(this.availableColumns, col => Utility.matches(col.header, $event.query));

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
    this.forceUpdate();
  }

  public selectColumn($event: any): void {
    this.forceUpdate();
  }

  private init(): void {
    if (this.entityName) {
      this.entityMeta = this.modelService.getEntity(this.entityName);
      this.setRecordEntity(this.recordEntity ?? this.entityName);
    }

    this.loaded = false;
    // no starting value given, create empty query
    if (_.isEmpty(this.value)) {
      this.clear();
    }
    if (!this.multiple) {
      // make sure the given json has ids for removal
      PortalUtil.addQueryIds(this.value as CriteriaQueryGroupJson);
    }

    this.loaded = true;
    this.cdr.detectChanges();
  }
}
