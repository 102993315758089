<login-authentication-page (onEnter)="save()">
  <ng-template #header>
    <span class="one-login__greeting--big one-login__greeting--welcome">{{ 'SelfRegistration.Greeting' | translate }} </span>
    <span>{{ 'SelfRegistration.Welcome' | translate }}</span>
  </ng-template>
  <ng-template #content>
    <form #form="ngForm" class="one-login__form" method="post">
      <div class="one-login__form-fields">
        <ng-container ngModelGroup="username">
          <ui-input-text [(ngModel)]="user.username" [require]="true" label="{{'PlatformUser.username.Value' | translate}}" name="username"></ui-input-text>
        </ng-container>
        <ng-container ngModelGroup="password">
          <ui-password-input [(ngModel)]="user.password" [disableAutofill]="true" [require]="true"
                             [strongRegex]="'^(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W|_])(?=.*[0-9])(?=.{8,})'"
                             requirementsText="{{'Password.HtmlMessage' | translate}}" label="{{'UserProfile.ChangePassword.Password' | translate}}"
                             name="password"></ui-password-input>
        </ng-container>
        <ng-container ngModelGroup="email">
          <ui-input-text [(ngModel)]="user.email" [require]="true" label="{{'PlatformUser.email.Value' | translate}}" name="email" type="text"></ui-input-text>
        </ng-container>
        <ng-container ngModelGroup="firstName">
          <ui-input-text [(ngModel)]="user.firstName" label="{{'PlatformUser.firstName.Value' | translate}}" name="firstName" type="text"></ui-input-text>
        </ng-container>
        <ng-container ngModelGroup="lastName">
          <ui-input-text [(ngModel)]="user.lastName" label="{{'PlatformUser.lastName.Value' | translate}}" name="lastName" type="text"></ui-input-text>
        </ng-container>
      </div>
      <footer class="one-login__footer">
        <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt" label="{{'SelfRegistration.Submit' |
          translate}}" styleClass="one-login__button" type="primary"></ui-button>
      </footer>
    </form>
  </ng-template>
</login-authentication-page>