import {AbstractModelTranslationService} from '..';

export interface TranslateService {
  instant(key: string, language?: string, params?: any): string;

  get defaultLang(): string;

  set(key: string, value: string | Record<string, unknown>, language: string, application?: string): Promise<void> | void;
}

export abstract class AbstractKolibriTranslate {
  protected constructor(private translateService: TranslateService, public model: AbstractModelTranslationService) {

  }

  public get defaultLang(): string {
    return this.translateService.defaultLang;
  }

  public instant(key: string, language?: string, params?: any): string {
    return this.translateService.instant(key, language, params);
  }

  public set(key: string, value: string | Record<string, unknown>, language: string, application?: string): Promise<void> | void {
    return this.translateService.set(key, value, language, application);
  }
}
