import {_} from '@wspsoft/underscore';
import {KolibriEntity} from '../model/database/kolibri-entity';

import {Utility} from '../util/utility';
import {CriteriaCondition} from './criteria-condition';
import {CriteriaFunction} from './criteria-function';
import {CriteriaOperator} from './criteria-operator';
import {CriteriaSearchOperator} from './criteria-search-operator';
import {ConditionValueType} from './json/criteria-condition-json';
import {CriteriaQueryGroupJson} from './json/criteria-query-group-json';

export class CriteriaQueryGroup<E extends KolibriEntity> {
  public active: boolean;
  public groups: CriteriaQueryGroup<E>[] = [];
  public whereCondition: CriteriaCondition[] = [];
  public searchCondition: CriteriaCondition<CriteriaSearchOperator>[] = [];
  public useOr: boolean;

  public constructor(useOr: boolean = false, active: boolean = true) {
    this.useOr = useOr;
    this.active = active;
  }


  // noinspection JSUnusedGlobalSymbols
  public addGroup(useOr: boolean = false): CriteriaQueryGroup<E> {
    const criteriaGroup = new CriteriaQueryGroup(useOr);
    this.groups.push(criteriaGroup);
    return criteriaGroup;
  }

  // noinspection JSUnusedGlobalSymbols
  public andGroup(): CriteriaQueryGroup<E> {
    return this.addGroup(false);
  }

  // noinspection JSUnusedGlobalSymbols
  public orGroup(): CriteriaQueryGroup<E> {
    return this.addGroup(true);
  }

  /**
   * adds an arango ngram search condition, no dot walks possible ever!
   */
  public addSearchCondition<C extends keyof E & string>(columnName: C, operator: CriteriaSearchOperator, rightHandValue1?: E[C], useOr?: boolean,
                                                        criteriaFunction?: CriteriaFunction, options?: any): this;
  public addSearchCondition(columnName: string, operator: CriteriaSearchOperator,
                            rightHandValue1?: any, useOr?: boolean, criteriaFunction?: CriteriaFunction, options?: any): this;
  public addSearchCondition<C extends keyof E & string>(columnName: C | string, operator: CriteriaSearchOperator,
                                                        rightHandValue1?: E[C] | any, useOr: boolean = false, criteriaFunction?: CriteriaFunction,
                                                        options?: any): this {
    return this.pushCondition(operator, rightHandValue1, 'searchCondition', columnName, useOr, criteriaFunction, undefined, options);
  }

  public and<C extends keyof E & string>(columnName: C, operator: CriteriaOperator, rightHandValue1?: E[C], rightHandValue2?: any,
                                         criteriaFunction?: CriteriaFunction, options?: any, scripted?: ConditionValueType): this;
  public and(columnName: string, operator: CriteriaOperator, rightHandValue1?: any, rightHandValue2?: any, criteriaFunction?: CriteriaFunction, options?: any,
             scripted?: ConditionValueType): this;
  public and<C extends keyof E & string>(columnName: C | string, operator: CriteriaOperator, rightHandValue1?: E[C] | any, rightHandValue2: any = '',
                                         criteriaFunction?: CriteriaFunction, options?: any, scripted?: ConditionValueType): this {
    return this.addCondition(columnName, operator, rightHandValue1, false, rightHandValue2, criteriaFunction, options, scripted);
  }

  public or<C extends keyof E & string>(columnName: C, operator: CriteriaOperator, rightHandValue1?: E[C], rightHandValue2?: any,
                                        criteriaFunction?: CriteriaFunction, options?: any, scripted?: ConditionValueType): this;
  public or(columnName: string, operator: CriteriaOperator, rightHandValue1?: any, rightHandValue2?: any, criteriaFunction?: CriteriaFunction, options?: any,
            scripted?: ConditionValueType): this;
  public or<C extends keyof E & string>(columnName: C | string, operator: CriteriaOperator, rightHandValue1?: E[C] | any, rightHandValue2: any = '',
                                        criteriaFunction?: CriteriaFunction, options?: any, scripted?: ConditionValueType): this {
    return this.addCondition(columnName, operator, rightHandValue1, true, rightHandValue2, criteriaFunction, options, scripted);
  }

  public addCondition<C extends keyof E & string>(columnName: C, operator: CriteriaOperator, rightHandValue1?: E[C], useOr?: boolean,
                                                  rightHandValue2?: any, criteriaFunction?: CriteriaFunction, options?: any,
                                                  scripted?: ConditionValueType): this;
  public addCondition(columnName: string, operator: CriteriaOperator, rightHandValue1?: any, useOr?: boolean, rightHandValue2?: any,
                      criteriaFunction?: CriteriaFunction, options?: any, scripted?: ConditionValueType): this;
  public addCondition<C extends keyof E & string>(columnName: C | string, operator: CriteriaOperator,
                                                  rightHandValue1?: E[C] | any, useOr: boolean = false, rightHandValue2: any = '',
                                                  criteriaFunction?: CriteriaFunction, options?: any,
                                                  scripted?: ConditionValueType): this {
    return this.pushCondition(operator, rightHandValue1, 'whereCondition', columnName, useOr, rightHandValue2, criteriaFunction, options, scripted);
  }

  // noinspection JSUnusedGlobalSymbols
  public addPercentBasedCondition<C extends keyof E & string>(columnName: C | string, rightHandValue1: string = '', useOr: boolean = false,
                                                              preferredDefaultSearchOperator?: CriteriaOperator): this {
    const operator = Utility.getSearchOperator(rightHandValue1, preferredDefaultSearchOperator);

    return this.addCondition(columnName, operator, Utility.stripPercentByOperator(operator, rightHandValue1) as any, useOr);
  }

  public fromJson(json: CriteriaQueryGroupJson): this {
    for (const group of json.groups || []) {
      const criteriaQueryGroup = new CriteriaQueryGroup<E>();
      criteriaQueryGroup.fromJson(group);

      if (criteriaQueryGroup.whereCondition.length || criteriaQueryGroup.groups.length) {
        this.groups.push(criteriaQueryGroup);
      }
    }
    this.conditionFromJson(json, 'searchCondition');
    this.conditionFromJson(json, 'whereCondition');

    this.useOr = json.useOr;
    this.active = json.active;
    return this;
  }

  public getJson(): CriteriaQueryGroupJson {
    return {
      groups: this.groups.map(value => value.getJson()),
      whereCondition: this.whereCondition.map(value => value.getJson()).filter(value => value.columnName),
      searchCondition: this.searchCondition.map(value => value.getJson()).filter(value => value.columnName),
      useOr: this.useOr,
      active: this.active
    };
  }

  private conditionFromJson(json: CriteriaQueryGroupJson, type: string): void {
    for (const condition of json[type] || []) {
      const criteriaCondition = new CriteriaCondition();
      criteriaCondition.fromJson(condition);
      if (!criteriaCondition.isEmpty()) {
        this[type].push(criteriaCondition);
      }
    }
  }

  private pushCondition(operator: CriteriaOperator | CriteriaSearchOperator,
                        rightHandValue1: any, type: string, columnName: string, useOr: boolean, rightHandValue2?: any, criteriaFunction?: CriteriaFunction,
                        options?: any, scripted?: ConditionValueType): this {
    if (operator === CriteriaOperator.IS || operator === CriteriaOperator.IS_NOT) {
      // in case we added an entity we want to create the aql with id matching and not real object comparison
      rightHandValue1 = _.isObject(rightHandValue1) && 'id' in rightHandValue1 ? rightHandValue1.id : rightHandValue1;
    }

    this[type].push(new CriteriaCondition(columnName, operator, useOr, rightHandValue1, rightHandValue2, scripted, criteriaFunction, options));
    return this;
  }
}
