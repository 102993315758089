import {HttpClient} from '@angular/common/http';
import {AfterContentInit, Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {AuthService, SettingsService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../form.scss']
})
export class LoginComponent extends FormComponent implements OnInit, AfterContentInit {
  public title: string = 'login';
  public username: string;
  public password: string;
  public error: boolean;
  public ready: boolean;
  public allowSelfRegistration: boolean;
  public allowPasswordReset: boolean;
  public showMultiAuthErrorMessage: boolean = false;
  public retries: number = 0;

  public constructor(private httpClient: HttpClient, messageService: MessageService, translateService: TranslateService, elementRef: ElementRef,
                     public settingsService: SettingsService,
                     private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private zone: NgZone) {
    super(messageService, translateService, elementRef);
    this.showSuccessMessage = false;
  }

  public get multiAuthErrorMessage(): string {
    return this.settingsService.platformSettings.multiAuthErrorMessage || this.translateService.instant('Error.UnexpectedMessage');
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    // @ts-ignore
    if (document.activeElement?.blur) {
      // @ts-ignore
      document.activeElement.blur();
    }
    try {
      await this.authService.login(this.username?.trim(), this.password,
        this.activatedRoute.snapshot.queryParamMap.get('prevLoginMethod'),
        this.activatedRoute.snapshot.queryParamMap.get('loginMethod'),
        this.activatedRoute.snapshot.queryParamMap.get('loginUrl')
      );
      this.error = false;
      window.location.href = '/';
      return true;
    } catch (e: any) {
      if (e.status === 500) {
        this.showMultiAuthErrorMessage = true;
        return false;
      }

      this.retries++;
      this.error = true;
      return false;
    }
  }

  public ngOnInit(): void {
    this.allowSelfRegistration = (window as any).selfRegistration;
    this.allowPasswordReset = (window as any).passwordReset;
  }

  public navigateToRegistration(): Promise<boolean> {
    return this.router.navigate(['auth', 'register']);
  }

  public navigateToPasswordReset(): Promise<boolean> {
    return this.router.navigate(['auth', 'requestPasswordReset']);
  }
}
