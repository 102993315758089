import {ChangeDetectionStrategy, Component, forwardRef, Host, Input, Optional, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_} from '@wspsoft/underscore';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputNumberComponent extends CustomInput<number | number[]> {
  @Input()
  public locale: string;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public showButtons: boolean = true;
  @Input()
  public showSeparator: boolean = true;
  @Input()
  public allowEmpty: boolean = true;
  @Input()
  public step: number = 1;
  @Input()
  public buttonLayout: 'stacked' | 'horizontal' | 'vertical' = 'horizontal';
  @Input()
  public max: number;
  @Input()
  public min: number;
  @Input()
  public maxFractionDigits: number;
  @Input()
  public minFractionDigits: number;
  @Input()
  public currency: string;

  public get filled(): boolean {
    // we can't use the default "filled" function of primeng for input numbers anymore because when using chips(multiple) we have 2 native inputs
    return !_.isNullOrEmpty(this.value);
  }

  public getNumberLabel(): string {
    if (!this.label) {
      return null;
    }
    return this.label + (!this.renderInputGroup ? ':' : '');
  }

  public getInputSize(): number {
    return _.isNullOrEmpty(this.value) || Array.isArray(this.value) ?
      1 : parseFloat(this.value.toFixed(this.maxFractionDigits)).toString().length + 1;
  }
}
