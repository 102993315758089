/* eslint-disable */
// noinspection JSUnusedGlobalSymbols

import {_, MaybePromise} from '@wspsoft/underscore';
import * as chroma from 'chroma-js';
import * as moment from 'moment-timezone';
import * as uuidv4 from 'uuid-random';
import validator from 'validator';
import {
  AbstractKolibriScriptContext,
  Attachment,
  CriteriaQuery,
  CriteriaQueryJson,
  CustomActivityStatistics,
  CustomValidationMessage,
  EmailHeaders,
  EmailMessage,
  EntityWriteOptions,
  KolibriEntity,
  KolibriTask,
  Layout,
  MessageTemplate,
  User,
  ValidationLevel,
  VariableEntity
} from '../index';

export interface ActivityStreamSettings {
  defaultMode?: ActivityStreamModes;
  defaultFilter?: ActivityLogType[];
  defaultContent?: CustomActivityMessage;
}

export enum ActivityStreamModes {
  internalMessage = 'internalMessage', externalMessage = 'externalMessage', email = 'email'
}

enum ActivityLogType {
  externalMessage = 'externalMessage', appMessage = 'appMessage', email = 'email', internalMessage = 'internalMessage', relation = 'relation',
  change = 'change', created = 'created', deleted = 'deleted'
}

enum S3Protocols {
  Https = 'https', Http = 'http', S3 = 's3'
}

abstract class CustomActivityMessage {
  public body: string;
  public header?: string;
  public type: ActivityLogType;
  public recipients?: string[];
  public messageTemplate?: MessageTemplate;
  public attachments?: Attachment[];
  public send?: boolean;
  public statistics?: CustomActivityStatistics;
}

export enum DynamicContentType {
  PAGE_RIGHT = 'PAGE_RIGHT', DIALOG = 'DIALOG', WIZARD = 'WIZARD'
}

export enum ScriptCallable {
  FUNCTION = 'function', CLASS = 'class'
}

export enum DynamicContentSize {
  SMALL = 'small', MEDIUM = 'medium', LARGE = 'large'
}

export interface DynamicContent {
  key: string;
  layoutName: string;
  entityName?: string;
  type: DynamicContentType;
  label?: string;
  icon?: string;
  scriptData?: any;
  persistent?: boolean;
  background?: boolean;
  parentId?: string;
  delayedSave?: boolean;
  readonly?: boolean;
  closable?: boolean;
  size?: DynamicContentSize;
  data?: any;
  afterSave?: (e: KolibriEntity) => MaybePromise<any>;
  beforeSave?: (e: KolibriEntity) => MaybePromise<any>;
  afterClose?: () => MaybePromise<any>;
  onInit?: (e: KolibriEntity) => MaybePromise<any>;
}

export type ScriptEventCallback<T> = {
  cb?: (params: ScriptParams) => ScriptResult<T>;
  field?: string;
  script?: string;
  targetId?: string;
  fieldOnly?: boolean;
};

/**
 * usedFields: the fields used in the script. E.g. script: 'return record.active'. Active would be a used field
 * result: result of the script. E.g.: 'return record.active' would return a boolean.
 */
export interface ScriptResult<T> {
  usedFields: any;
  result: MaybePromise<T>;
}

export interface ScriptWizardPage {
  name: string;
  id: string;
  visible: boolean;
  description: string;
  data?: any;
}

export interface ScriptForm {
  /**
   * returns current form id
   */
  id: string;

  /**
   * get the parent from, if any
   */
  parent: ScriptForm;

  /**
   * returns current view mode
   */
  viewMode: number;

  /**
   * retrieve the script data from the current form
   */
  local: any;

  /**
   * retrieve the record from the current form
   */
  record: KolibriEntity;

  /**
   * check if current form is read only
   */
  readonly: boolean;

  /**
   * returns layout name of the form
   */
  layoutName: string;
  /**
   * get all nested forms
   */
  nestedForms: ScriptForm[];

  /**
   * skip leave message on current form
   */
  skipConfirmUnload(): void;

  /**
   * validate a specific attribute on current form
   */
  validateAttribute(inputName: string): boolean;

  /**
   * converts a message string to CustomValidationMessage
   */
  getValidationMessage(message: string, severity: ValidationLevel): CustomValidationMessage;

  /**
   * validates the current form with optional submit
   */
  checkForm(save?: boolean): Promise<boolean>;

  /**
   * validates and submits the current form
   */
  save(cb?: () => void, validate?: boolean): Promise<boolean>;

  /**
   * emit refresh event to current form
   */
  refresh(): void;

  /**
   * check if all inputs are valid
   */
  isValid(): boolean;

  /**
   * check if the submit condition is true
   */
  shouldDoNestedSaved(): MaybePromise<boolean>;

  /**
   * returns nested list with given name or undefined
   */
  nestedList(name: string): ScriptList;

  /**
   * return nested form with given name or undefined
   */
  nestedForm(name: string): ScriptForm;

  /**
   * return nested form with given name or undefined
   */
  nestedGraph(name: string): ScriptGraph;

  /**
   * return variable section with given name or undefined, if no name is given all variables sections will be loaded
   */
  variables(): ScriptVariable[];

  variables(name: string): ScriptVariable;

  variables(name?: string): ScriptVariable | ScriptVariable[];

  /**
   * add save success message
   */
  addSuccessMessage(): void;

  /**
   * skip save for nested form
   */
  skipNestedSave(name: string): void;
}

export interface ScriptDialog {
  // used for script buttons not for api (don't put in scriptLibrary.mustache)
  id: string;
  /**
   * get the list of the plus button
   */
  list: ScriptList;
  form: ScriptForm;

  show(data?: KolibriEntity): void;

  close(): void;
}

export interface ScriptList {
  hasColumnFilter: boolean;
  selection: KolibriEntity[];
  selectionMode: string;
  query: CriteriaQuery<KolibriEntity>;
  layoutName: string;

  /**
   * reload the list
   */
  reload(resetSelection?: boolean): void;

  /**
   * open the create dialog for the list
   */
  create(): void;
}


export interface GraphNode {
  id: string;
  entityClass: string;
  label: string;
  labelTemplate?: {
    html: string;
    pug: string;
    css: string;
    meta: {
      usedFields: string[];
      widgets?: string[];
    };
  };
  tooltip: string;
  height: number;
  width: number;
  imageUrl: string;
  color: string;
  icon: string;
  parents?: string[];
  expanded: boolean;
  display: string;
  data: KolibriEntity;
  // auto calculated
  labelWidth?: string;
  shape?: string;
  points?: string;
}

export interface GraphEdge {
  id?: string;
  display: string;
  source: string;
  target: string;
  color?: string;
  label?: string;
  additional?: boolean;
  value?: number;
  data?: KolibriEntity;
}

export interface ScriptWebsite {
  reload(): Promise<void>;
}

export interface ScriptGraph {
  cy: any;
  id: string;
  layoutName: string;

  /**
   * restore state from elements json
   */
  restoreFromJson(json: { nodes: any[]; edges: any[] }): void;

  /**
   * export and download the current visible graph as image
   */
  exportAsImage(): Promise<void>;

  /**
   * export all data as json
   */
  exportAsJson(): any;

  /**
   * action functions
   */

  refreshLayout(randomize?: boolean, fit?: boolean): void;

  /**
   * finally add the node to the graph and apply a tooltip for the full name
   */
  addNodeToGraph(node: GraphNode, opts?: GraphCreateNodeOptions): any;

  /**
   * add an edge to the graph
   */
  addEdgeToGraph(edge: GraphEdge): any;

  /**
   * zooms smoothly to all given nodes
   */
  zoomToNodes(node: any): Promise<void>;

  /**
   * search function for the ac return 10 matching nodes by label
   */
  searchNode($event: string): GraphNode[];

  getElementById(node: string): any;

  containsNode(node: GraphNode): boolean;

  containsEdge(node: GraphEdge): boolean;

  /**
   * open record in edit mode
   */
  editElement(record: KolibriEntity, element: any): Promise<void>;

  /**
   * remove edge by id
   */
  removeEdge(edge: GraphEdge): void;

  /**
   * remove node by id
   */
  removeNode(node: GraphNode): void;

  /**
   * remove all edges starting from node
   */
  removeNodeEdges(node: GraphNode): void;

  /**
   * clears graph
   */
  reset(): void;

  /**
   * refreshes the graph
   */
  refresh(): void;

  /**
   * special function for handling context menu tooltips
   *
   * abuses tippy.js for this
   */
  createContextMenuContent(iconClasses: string[], tooltipText: string): HTMLElement;

  /**
   * convert database data to a usable graph node
   */
  createNodeFromRecord(record: KolibriEntity, opts?: GraphRecordNodeOptions): GraphNode;

  /**
   * convert database data to a usable graph edge
   */
  createEdgeFromRecord(record: KolibriEntity, from: KolibriEntity, to: KolibriEntity): GraphEdge;

  /**
   * refreshes node html label
   */
  updateLabels(node: any, record: KolibriEntity): void;
}

export interface GraphRecordNodeOptions {
  tooltip?: boolean;
  icon?: boolean;
  shape?: string;
  points?: string;
  height?: number;
  width?: number;
  labelTemplate?: any;
}

export interface GraphCreateNodeOptions {
  position?: { x: number; y: number };
  renderPosition?: boolean;
}

export interface ScriptVariable {
  name: string;

  refresh(): Promise<void>;

  createNewEntity(params?: ScriptParams, options?: EntityWriteOptions): Promise<VariableEntity>;

  doSave(params?: ScriptParams): Promise<boolean>;
}

export interface ScriptWizard {
  data: any;
  currentPage: ScriptWizardPage;
  pages: { [key: string]: ScriptWizardPage };

  jumpToIndex(i: number): Promise<void>;

  jumpTo(name: string): Promise<void>;

  back(): Promise<void>;

  next(): Promise<void>;

  save(cb?: () => void): Promise<void>;

  validateCurrentPage(): Promise<boolean>;

  calculateRenderConditions(): Promise<void>;
}

export interface ScriptParams {
  id?: string;
  entityClass?: string;
  oneGraph?: ScriptGraph;
  oneWebsite?: ScriptWebsite;
  wizard?: ScriptWizard;
  dialog?: ScriptDialog;
  kf?: ScriptForm;
  kolibriList?: ScriptList;
  workflow?: ScriptWorkflow;
  /**
   * @see https://fullcalendar.io/docs#toc
   */
  kolibriCalendar?: any;
  layout?: Layout;
  socket?: any;
  data?: any;
  clickEvent?: MouseEvent | TouchEvent | KeyboardEvent;
  permissions?: ActivityStreamPermissions;
  streamSettings?: ActivityStreamSettings;
  local?: any;
  record?: KolibriEntity;
  recordOld?: KolibriEntity;
  recordParent?: KolibriEntity;
  query?: CriteriaQuery<KolibriEntity> | CriteriaQueryJson;
  queryString?: string;
  records?: KolibriEntity[];
  message?: EmailMessage;
  target?: KolibriEntity;
  oldValue?: any;
  newValue?: any;
  templateParams?: any;
  field?: string;
  viewId?: string;
  user?: User;
  emailHeaders?: EmailHeaders;
}

export interface ActivityStreamPermissions {
  internalMessageRead: boolean;
  internalMessageWrite: boolean;
  externalMessageRead: boolean;
  externalMessageWrite: boolean;
  emailRead: boolean;
  emailWrite: boolean;
  changeRead: boolean;
  createdRead: boolean;
  deletedRead: boolean;
  relationRead: boolean;
  appMessageRead: boolean;
}

export interface ScriptWorkflow {
  variables?: { [key: string]: any };
  processVariables?: { [key: string]: any };
  taskEntity?: KolibriTask;
  taskEntities?: KolibriTask[];
  nrOfInstances?: number;
  nrOfActiveInstances?: number;
  nrOfCompletedInstances?: number;
  execution?: any;
  localRecord?: KolibriEntity;
  user?: User;
  workflowInstanceId?: string;
  getParent?: any;
}

export interface ScriptStorage {
  [key: string]: any;
}

export interface ExportScope {
  global: ScriptStorage;
  local: { [key: string]: ScriptStorage };
}

export class KolibriScriptError {
  public static type: string = 'KolibriScriptError';
  public type: string = KolibriScriptError.type;

  public constructor(public message: string, public statusCode: number = 400, public suppressGrowl?: boolean) {
  }
}

export function createScriptParser(context: AbstractKolibriScriptContext): Generator<<T>(kc: ScriptParams) => MaybePromise<T>, Error, string> {
  const scriptParser = function* (): Generator<<T>(kc: ScriptParams) => MaybePromise<T>, Error, string> {
    let script = yield;
    while (true) {
      try {
        script = yield Function
        (
          'context', 'ks', 'global', 'conzole', '_',
          'chroma', 'moment', 'validator', 'uuidv4', 'require',
          'exports', 'console', script
        )(
          context, context.scriptLibrary, context.exports.global, context.conzole, _,
          chroma, moment, validator, uuidv4, globalThis.require,
          context.exports.global, context.conzole
        );
      } catch (e: any) {
        script = yield e;
      }
    }
  }();
  scriptParser.next();
  return scriptParser;
}
