export function rfdc() {
  const refs = new Set();

  return clone;

  function cloneArray(a) {
    const a2 = new Array(a.length);
    for (let k = 0; k < a.length; k++) {
      const cur = a[k];
      if (typeof cur !== 'object' || cur === null) {
        a2[k] = cur;
      } else if (typeof cur.toISOString === 'function') {
        a2[k] = new (cur.constructor as any)(cur);
      } else if (typeof cur.clone === 'function') {
        a2[k] = cur.clone();
      } else {
        if (!refs.has(cur)) {
          a2[k] = clone(cur);
        } else {
          a2[k] = cur;
        }
      }
    }
    return a2;
  }

  function clone(o) {
    if (typeof o !== 'object' || o === null) {
      return o;
    }
    if (typeof o.toISOString === 'function') {
      return new (o.constructor as any)(o);
    }
    if (typeof o.clone === 'function') {
      return o.clone();
    }
    if (Array.isArray(o)) {
      return cloneArray(o);
    }
    const o2 = Object.create(Object.getPrototypeOf(o));
    refs.add(o);
    for (const k in o) {
      const cur = o[k];
      if (typeof cur !== 'object' || cur === null) {
        o2[k] = cur;
      } else if (typeof cur.toISOString === 'function') {
        o2[k] = new (cur.constructor as any)(cur);
      } else if (typeof cur.clone === 'function') {
        o2[k] = cur.clone();
      } else {
        if (!refs.has(cur)) {
          o2[k] = clone(cur);
        } else {
          o2[k] = cur;
        }
      }
    }
    refs.delete(o);
    return o2;
  }
}