import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AggregationFunction,
  CriteriaOperator,
  CriteriaQuery,
  DateType,
  DisplayTransformation,
  EntityModel,
  Field,
  FilterType, KolibriEntity,
  OneTableState,
  RenderType,
  Type
} from '@wspsoft/frontend-backend-common';
import {ConfirmationService, MessageService, SortMeta, TableState} from 'primeng/api';
import {Table} from 'primeng/table';
import {CircularService, ModelService, TypeService} from '../../../../../../api';
import {OneConfirmService} from '../../../../service/one-confirm.service';
import {StructureService} from '../../../../service/structure.service';
import {ListUtil} from '../../../../util/list-util';
import {TableComponent} from '../../table.component';
import {ColumnPickerComponent} from '../column-picker/column-picker.component';
import {GroupingTableComponent} from '../groupingtable/grouping-table.component';
import {SimpleTableComponent} from '../simple-table/simple-table.component';


export interface DatatableColumn {
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  perPageAggregation?: boolean;
  totalAggregation?: boolean;
  aggregateInList?: boolean;
  aggregateInRelatedList?: boolean;
  showAggregation?: boolean;
  // field to read from data
  field: string;
  // field to use for display (can be different from field)
  displayField?: string;
  // col header text
  header: string;
  // type for boolean checkboxes etc
  typeName?: string;
  aggregation?: AggregationFunction;
  aggregationValue?: number;
  type?: Type;
  defaultFilterOperator?: string;
  // contains or begins with filter
  filterType?: FilterType;
  filterScript?: string;
  choiceFilterScript?: string;
  // convert value for display
  converter?: (obj: any) => any;
  // definition data from model xml
  meta?: Field;
  renderType?: RenderType;
  transformScript?: string;
  transformationEntity?: DisplayTransformation;
  sliderMinValue?: number;
  sliderMaxValue?: number;
  rounded?: boolean;
}

export interface ReorderEvent {
  dragIndex: number;
  dropIndex: number;
}

const handleRowRightClick = Table.prototype.handleRowRightClick;
// tslint:disable-next-line
Table.prototype.handleRowRightClick = function (event: any): boolean {
  if (event.originalEvent.ctrlKey) {
    return true;
  }
  event.originalEvent.stopPropagation();

  handleRowRightClick.bind(this)(event);
};


@Component({
  selector: 'ui-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableComponent extends TableComponent implements OnInit {
  @Input()
  public dataKey: string = 'name';
  @Input()
  public styleClass: string = '';
  @Input()
  public lazy: boolean = true;
  @Input()
  public columnToggler: boolean;
  @Input()
  public allowPdfExport: boolean = true;
  @Input()
  public allowXlsExport: boolean = true;
  @Input()
  public allowJsonExport: boolean = true;
  @Input()
  public allowCsvExport: boolean = true;
  @Input()
  public resizeable: boolean = false;
  @Input()
  public compact: boolean = false;
  @Input()
  public columns: DatatableColumn[];
  @Input()
  public availableColumns: DatatableColumn[];
  @Input()
  public multiSortMeta: SortMeta[];
  @Input()
  public entityMeta: EntityModel;
  @Input()
  public rowGroupMode: string;
  @Input()
  public groupRowsBy: string;
  @Input()
  public sortField: string;
  @Input()
  public canUseQueryBuilder: boolean = true;
  @Input()
  public enableGrouping: boolean = false;
  @Input()
  public fixedGrouping: boolean = false;
  @Input()
  public query: CriteriaQuery<KolibriEntity>;
  @Input()
  public groupingChild: boolean = false;
  @Input()
  public groupByField: string;
  @Output()
  public onFilter: EventEmitter<{ field: string; value: any; operator: CriteriaOperator }> = new EventEmitter();
  @Output()
  public onRowReorder: EventEmitter<ReorderEvent> = new EventEmitter();
  @Output()
  public onStateSave: EventEmitter<TableState> = new EventEmitter();
  @Output()
  public onColumnChange: EventEmitter<OneTableState> = new EventEmitter();
  @Output()
  public onEditCancel: EventEmitter<any> = new EventEmitter();
  @Output()
  public onEditSave: EventEmitter<any> = new EventEmitter();
  @Output()
  public onGroupByFieldChange: EventEmitter<string> = new EventEmitter();
  @ViewChild(SimpleTableComponent, {static: false})
  public simpleTable: SimpleTableComponent;
  @ViewChild(GroupingTableComponent, {static: false})
  public groupingTable: GroupingTableComponent;
  @ViewChild('columnPicker')
  public columnPicker: ColumnPickerComponent;
  public FilterType = FilterType;
  public DateType = DateType;
  public grouping: boolean = false;

  public constructor(translate: TranslateService, confirmationService: ConfirmationService, cdr: ChangeDetectorRef, protected circularService: CircularService,
                     messageService: MessageService, public typeUtility: TypeService, oneConfirmService: OneConfirmService,
                     protected structureService: StructureService, protected modelService: ModelService) {
    super(translate, confirmationService, messageService, oneConfirmService, cdr);
  }

  public get tableId(): string {
    return this.simpleTable ? this.simpleTable.table.id : this.groupingTable.table.id;
  }

  public get tableState(): TableState {
    return (this.simpleTable ?? this.groupingTable).tableState;
  }

  public get state(): TableState {
    const stateString = sessionStorage.getItem(this.name);
    if (stateString) {
      return JSON.parse(stateString);
    }
  }

  @Input()
  public rowTrackBy: TrackByFunction<any> = (index) => index;

  public ngOnInit(): void {
    // prime ng bug with empty multi sort meta
    if (!this.lazy && this.multiSortMeta?.length === 0) {
      this.multiSortMeta = undefined;
    }
    this.initGrouping();
    this.setupContextMenu();
  }

  /**
   * enabling edit mode for all rows on page.
   */
  public enableEditForAll(): void {
    this.simpleTable?.enableEditForAll();
    this.groupingTable?.enableEditForAll();
  }

  /**
   * saves all rows with enabled row edit and disabling edit mode for all rows on page.
   * the dirty flag will be checked in onEditSave
   */
  public saveAllEdited(): void {
    this.simpleTable?.saveAllEdited();
    this.groupingTable?.saveAllEdited();
  }

  /**
   * cancels all rows with enabled row edit and disabling edit mode for all rows on page.
   */
  public cancelAllEdited(): void {
    this.simpleTable?.cancelAllEdited();
    this.groupingTable?.cancelAllEdited();
  }

  public refresh(clearSelection?: boolean, externalRefresh: boolean = true): void {
    if (this.enableGrouping) {
      this.checkGrouping();
      this.cdr.detectChanges();
    }
    this.simpleTable?.refresh(clearSelection, externalRefresh);
    this.groupingTable?.refresh(clearSelection, externalRefresh);
  }

  /**
   * do a full table count
   */
  public count(): void {
    // do nothing it is not used
  }

  public doContextFieldSelection($event: string): void {
    this.groupByField = $event;
    this.onGroupByFieldChange.emit($event);
    try {
      this.values = [];
      this.checkGrouping(true);
      // set first to 0 when grouping changes
      this.tableState.first = 0;
      this.doStateSave(this.tableState);
    } catch (error) {
      console.error(error);
    }
  }

  public doStateSave(state: TableState): void {
    state = {
      ...(state ?? {}),
      // @ts-ignore
      groupByField: this.groupByField
    };
    this.onStateSave.emit(state);
  }

  private initGrouping(): void {
    if (this.enableGrouping) {
      // @ts-ignore
      const groupingField = this.state?.groupByField;
      if (groupingField) {
        this.groupByField = groupingField;
      }
      this.checkGrouping();
    }
  }

  private checkGrouping(throwError?: boolean): void {
    try {
      this.checkGroupByValidity();
      this.grouping = !!this.groupByField;
    } catch (error) {
      if (throwError) {
        throw error;
      }
    }
  }

  /**
   * if group by field is set and not a valid column this method throws an exception
   *
   * @throws Error if group by is no valid column
   * @private
   */
  private checkGroupByValidity(): void {
    if (this.groupByField && !this.columns.find(col => col.field === this.groupByField)) {
      ListUtil.throwNoColumnMessage(this.groupByField, this.entityMeta, this.modelService, this.translate, this.messageService);
      throw new Error('Column of groupBy field is required');
    }
  }
}
