import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {NotificationRuleContent} from './notification-rule-content';
import {Rule} from './rule';

export enum NotificationRuleTrigger {
  INSERT = 'insert',
  UPDATE = 'update',
  DELETE = 'delete',
  INCOMING = 'incoming',
  SUBSCRIPTION = 'subscription'
}

export abstract class NotificationRule extends Rule {
  public trigger?: NotificationRuleTrigger[];
  public condition?: CriteriaQueryJson | string;
  public contents?: NotificationRuleContent[];
  public order?: number;
  public sendToEventCreator?: boolean;
  public recipientsScripted?: boolean;
}
