import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SettingsService} from '../../../../../api';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public constructor(public settingsService: SettingsService) {
  }
}
