<ui-input-wrapper [disable]="disable" [hasLabelAlwaysUp]="true" [label]="label" [renderInputGroup]="renderInputGroup" [require]="require">
  <p-dropdown (onHide)="onHide.emit($event)" (onShow)="onShow.emit($event)" [(ngModel)]="value" [autoDisplayFirst]="autoDisplayFirst" [dataKey]="dataKey"
              [disabled]="disable" [dropdownIcon]="dropdownIcon" [editable]="false" [filter]="filter" [group]="!!groupBy"
              [ngClass]="{'one-input': renderInputGroup}" [optionLabel]="optionLabel" [options]="options" [overlayOptions]="{styleClass: overlayStyleClass}"
              [panelStyleClass]="panelStyleClass" [placeholder]="placeholder" [required]="require" [resetFilterOnHide]="false" [styleClass]="styleClass"
              appendTo="body" emptyFilterMessage="{{emptyMessage | translate: {minCharacters} }}"
              emptyMessage="{{emptyMessage |  translate: {minCharacters} }}">
    <ng-template *ngIf="!!groupBy" let-group pTemplate="group">
      <ui-field-translator [data]="group.items[0]" [field]="groupBy" [translateNull]="true"></ui-field-translator>
    </ng-template>
    <ng-template *ngIf="itemTemplate" let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
    </ng-template>
    <ng-template *ngIf="!itemTemplate" let-item pTemplate="item">
      <ui-field-translator [data]="item" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
    </ng-template>
    <ng-template *ngIf="filterTemplate" pTemplate="filter">
      <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
    </ng-template>
    <ng-template pTemplate="filtericon">
      <span class="fa fa-search"></span>
    </ng-template>
  </p-dropdown>
</ui-input-wrapper>

